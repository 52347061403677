import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import bgimage from "../Images/cloudashbg.png";
import icon from "../Images/cloudashicon1.png";

const Landing = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${bgimage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        width:"100%",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        zIndex: 0,
        overflowX: "hidden",
        px: { xs: 2, sm: 4, md: 6 }, // Add padding for better spacing
      }}
    >
      {/* Overlay for Dark Effect */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1,
        }}
      />

      {/* Content */}
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        sx={{
          width: "100%",
          margin: 0,  
          color: "#fff",
          textAlign: "center",
          position: "relative",
          zIndex: 2,
          overflowX: "hidden", 
        }}
      >
        {/* Right Content - Icon (First on Mobile, Second on Desktop) */}
        <Grid
          item
          xs={10}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            order: { xs: 1, md: 2 },
          }}
        >
       <Box
          component="img"
          src={icon}
          alt="Auto CI-CD Icon"
          sx={{
            width: "100%", 
            maxWidth: "550px",
            height: "auto",
            objectFit: "contain",
            display: "block", // Ensures no extra margins
          }}
        />
        </Grid>

        {/* Left Content - Text (Second on Mobile, First on Desktop) */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            order: { xs: 2, md: 1 },
            textAlign: { xs: "center", md: "left" },
            px: { xs: 2, sm: 4, md: 6 }, 
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontFamily: "Montserrat",
              fontSize: { xs: "26px", sm: "32px", md: "36px" },
              fontWeight: "bold",
              lineHeight: 1.3,
              display: "inline-block"
            }}
          >
                Cloudash
            <Box
            sx={{
              width: "288px",
              height: "10px",
              backgroundColor: "#FAD32E", // Change color as needed
              borderRadius: "5px",
              marginTop: "8px",
            }}
            />
          </Typography>
          <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "Montserrat",
                    mt: 2,
                    fontSize: { xs: "14px", sm: "16px", md: "18px" },
                    width: "100%", // Ensure it doesn't take full width
                    maxWidth: "450px", // Prevent it from being too wide
                    fontWeight: "bold",
                    lineHeight: 1.5,
                  }}
                >
Elevate Your Cloud Experience with Cloudash: A Smarter, Cost-Effective Approach to VM Deployment          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Landing;
