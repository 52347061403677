
import HashinfraLandingPage from "./SectionsHashInfra/sec1";
import Section2 from "./SectionsHashInfra/sec2";
import Section3 from "./SectionsHashInfra/sec3";
import Section4 from "./SectionsHashInfra/sec4";
import Section5 from "./SectionsHashInfra/sec5";
import Section6 from "./SectionsHashInfra/sec6";
import Section7 from "./SectionsHashInfra/sec7";
import Section8 from "./SectionsHashInfra/sec8";
import Section10 from "./SectionsHashInfra/sec10";

import React from 'react'

const Hashinfra = () => {
  return (
    <div>
      <HashinfraLandingPage />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section10 />
      <Section8 />
      
    </div>
  )
}

export default Hashinfra


