import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import bgimg2 from "../Images/bgimg2.png";

const features = [
  { title: "Auto Scalability", description: "Adjust your setup on the fly to keep up with changing needs making sure everything runs." },
  { title: "Robust Security", description: "Implement industry-standard practices to secure your microservices and data." },
  { title: "Disaster Recovery", description: "Lower risks by using Velero's quick and easy backup and recovery tools." },
  { title: "Real-Time Observability", description: "Works with Prometheus and Grafana to provide actionable insights across your ecosystem." },
  { title: "Streamlined Automation", description: "Mitigate risks with Velero's efficient backup and recovery tools." },
  { title: "Cost Efficiency", description: "Optimize infrastructure for maximum performance with minimum cost." },
  { title: "High Availability", description: "Keep things running well with reliable ways to scale up and recovery options." },
];

const Section3 = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${bgimg2})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundColor: "rgba(0, 0, 0, 0.95)",
        height: { xs: "auto", md: "1100px" },  // Responsive height
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: { xs: 8, md: 10 },  // Responsive padding
        paddingBottom: { xs: 8, md: 10 },
        position: "relative",
      }}
    >
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{
          fontWeight: "medium",
          color: "#FAD32E",
          fontSize: { xs: "22px", md: "25px" },  // Responsive font size
          fontFamily: "Montserrat",
          marginBottom: { xs: 4, md: 6 },  // Space below heading
        }}
      >
        CORE FEATURES
      </Typography>

      <Grid
        container
        spacing={4}
        justifyContent="center"
        pl={{ xs: 2, sm: 5, md: 10 }}  // Responsive padding
        pr={{ xs: 2, sm: 5, md: 10 }}
      >
        {features.map((feature, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={index}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: "100%",
                maxWidth: "550px",
                minHeight: "250px",
                borderRadius: "8px",
                backgroundColor: "#000000",
                padding: "20px",
                boxShadow: "0px 4px 6px rgba(250, 211, 46, 0.48)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                position: "relative", 
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "semibold",
                  color: "#f0d000",
                  marginBottom: "10px",
                  fontFamily: "Montserrat",
                  fontSize: { xs: "18px", md: "22px",lg:"24px" },  // Responsive font size
                }}
              >
                {feature.title}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#dcdcdc",
                  fontFamily: "Montserrat",
                  fontSize: { xs: "14px", md: "16px" },  // Responsive font size
                }}
              >
                {feature.description}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Section3;
