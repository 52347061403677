import React from "react";
import { Box, Typography, Button } from "@mui/material";
import ellipse2 from "../Images/Ellipse1.png";
import ellipse3 from "../Images/Ellipse2.png";
import { useNavigate } from "react-router-dom";

const Section10 = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: { xs: "200px", md: "275px" },  // Responsive height
        backgroundColor: "#0E0E0E",
        position: "relative",
        overflow: "hidden",
        px: 2,  // Add padding to prevent content from touching edges on small screens
      }}
    >
      {/* Main Content Box */}
      <Box
        sx={{
          position: "relative",
          textAlign: "center",
          padding: 4,
          backgroundColor: "#0E0E0E",
          borderRadius: 2,
          //boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          zIndex: 2,
          maxWidth: "90%",  // Prevents content from stretching too wide on larger screens
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            marginBottom: 3,
            color: "#FAD32E",
            fontFamily: "Montserrat",
            fontSize: { xs: "20px", md: "30px" },  // Responsive font size
          }}
        >
          Ready to Transform Your DevOps Workflows?
        </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "transparent",
            color: "#FAD32E",
            border: "1px solid #FAD32E",
            padding: { xs: "6px 12px", md: "8px 16px" },  // Responsive padding
            fontWeight: 600,
            fontSize: { xs: "14px", md: "16px" },  // Responsive font size
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#FAD32E",
              color: "#000000",
            },
          }}
          onClick={() => navigate("/form")}
        >
          Get Started Today
        </Button>
      </Box>

      {/* Decorative Ellipses */}
      <img
        src={ellipse2}
        alt="Ellipse"
        style={{
          position: "absolute",
          top: "10%",
          left: "-2%",
          width: "75px",
          height: "95px",
          width: window.innerWidth < 600 ? "50px" : "75px",  // Responsive size
          height: window.innerWidth < 600 ? "65px" : "95px",
          zIndex:'2'
        }}
      />
      {/* <img
        src={ellipse3}
        alt="Ellipse"
        style={{
          position: "absolute",
          bottom: "-5%",
          right: window.innerWidth < 600 ? "5%" : "20%",  // Adjust position for mobile
          width: window.innerWidth < 600 ? "70px" : "105px",  // Responsive size
          height: window.innerWidth < 600 ? "50px" : "75px",
          zIndex: 2,
        }}
      /> */}
    </Box>
  );
};

export default Section10;
