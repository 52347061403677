import React, { useEffect, useState } from 'react';
import http from '../../../httpService';
import PhoneInput from 'react-phone-input-2';
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
// import { useLinkedIn } from 'react-linkedin-login-oauth2';

// function LinkedInPage() {
//     const { linkedInLogin } = useLinkedIn({
//         clientId: process.env.REACT_APP_REACT_CLIEND_ID,
//         redirectUri: `${window.location.origin}/linkedin`,
//         onSuccess: async (code) => {
//             try {
//                 const formData = new FormData();
//                 formData.append('role-id', '7');
//                 formData.append('code_data', code);
//                 const response = await http.post('api/hirerlinkedinlogin', formData);
//                 if (response && response.data) {
//                     localStorage.setItem('AuthData', JSON.stringify({
//                         user_name: response.data.user_name,
//                         auth_token: response.data.access_token,
//                         auth_role_id: response.data.user_role_id,
//                         auth_token_type: response.data.token_type,
//                     }));

//                     if (response.data.user_role_id == '7') {
//                         window.location.href = '/my-account-employer';
//                     } else {
//                         window.location.href = '/my-account';
//                     }
//                 }
//             } catch (exception) {
//                 if (exception.response && exception.response.data) {
//                     console.log(exception.response.data);
//                 }

//             }
//         },
//         onError: (error) => {
//             console.log(error);
//         },
//     });

//     return (
//         <img
//             onClick={linkedInLogin}
//             src='/img/linkedin.png'
//             alt="Sign in with Linked In"
//             style={{ maxWidth: '100%', cursor: 'pointer' }}
//         />
//     );
// }

export default function RecruiterSignup() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneCountryCode, setPhoneCountryCode] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmpassword, setConfirmPassword] = useState('');
    const [companyname, setCompanyname] = useState('');
    const [companyaddress, setCompanyAddress] = useState('');
    const [errors, setErrors] = useState({
        firstname: undefined,
        lastname: undefined,
        phone: undefined,
        email: undefined,
        password: undefined,
        confirmpassword: undefined
    });

    const handleOnChangework = (value, data, event, formattedValue) => {
        setPhoneCountryCode(event.target.value);
    }

    useEffect(() => {
        function start() {
            gapi.client.init({
                clientId: process.env.REACT_APP_GOOGLE_SECRET_ID,
                scope: ""
            })
        }

        gapi.load('client:auth2', start);
    });

    const addSubmit = async () => {
        let isValid = true;
        /*
        if (confirmpassword === password) {
            isValid = true;
        } else {
            isValid = false;
        }
        */

        if (isValid) {
            const formData = new FormData();
            formData.append('firstname', firstName);
            formData.append('lastname', lastName);
            formData.append('phone', phoneCountryCode);
            formData.append('email', email);
            formData.append('password', password);
            formData.append('password_confirmation', confirmpassword);
            formData.append('companyname', companyname);
            formData.append('companyaddress', companyaddress);

            try {
                let response = await http.post("api/register/job-recruiter", formData);

                if (response && response.data) {
                    let tabbtns = document.querySelector('.glassCard .tabBtns');
                    let logintab = document.querySelector('.glassCard #login');
                    let signtab = document.querySelector('.glassCard #signup');
                    let successmessage = document.querySelector('#topInnerPage #resumeSuccess');
                    tabbtns.style.display = 'none';
                    logintab.style.display = 'none';
                    signtab.style.display = 'none';
                    successmessage.style.display = 'block';
                    window.scrollTo(0, 0);
                }

            } catch (exception) {
                if (exception.response && exception.response.data) {
                    setErrors(exception.response.data);
                }
            }

        } else {
            // setErrors({confirmpassword: "Please enter same password."});
        }
    }

    const successResponseGoogle = async (response) => {
        if (response.profileObj) {
            const formData = new FormData();
            formData.append('role-id', '7');
            formData.append('profile', JSON.stringify(response.profileObj));
            try {
                const response = await http.post('api/googlesignup', formData);
                if (response && response.data) {
                    localStorage.setItem('AuthData', JSON.stringify({
                        user_name: response.data.user_name,
                        auth_token: response.data.access_token,
                        auth_role_id: response.data.user_role_id,
                        auth_token_type: response.data.token_type,
                    }));

                    if (response.data.user_role_id == '7') {
                        window.location.href = '/my-account-employer';
                    } else {
                        window.location.href = '/my-account';
                    }
                }
            } catch (exception) {
                if (exception.response && exception.response.data) {
                    console.log(exception.response.data);
                }
            }
        }
    }

    const errorResponseGoogle = (response) => {
        console.log(response);
    }

    return (
        <>
            <form action="#" className="zForm white">
                <div className="inpGroup">
                    <label htmlFor="e-fname">Firstname*</label>
                    <input type="text" name="fname" id='fname' placeholder='John' onChange={e => setFirstName(e.target.value)} />
                </div>
                <div className="text-danger">{errors.firstname}</div>
                <div className="inpGroup">
                    <label htmlFor="e-lname">Lastname*</label>
                    <input type="text" name="lname" id='lname' placeholder='Doe' onChange={e => setLastName(e.target.value)} />
                </div>
                <div className="text-danger">{errors.lastname}</div>
                <div className="inpGroup">
                    <label htmlFor="e-phone">Phone*</label>
                    <PhoneInput country={'in'} placeholder="+91 XXXXX-XXXXX" name="phone" onChange={handleOnChangework} />
                </div>
                <div className="text-danger">{errors.phone}</div>
                <div className="inpGroup">
                    <label htmlFor="e-email">Email*</label>
                    <input type="text" name="email" id='email' placeholder='johndoe@email.com' onChange={e => setEmail(e.target.value)} />
                </div>
                <div className="text-danger">{errors.email}</div>
                <div className="inpGroup">
                    <label htmlFor="e-pswd">Password*</label>
                    <input type="password" name="pswd" id='pswd' placeholder='Your Password' onChange={e => setPassword(e.target.value)} />
                </div>
                <div className="text-danger">{errors.password}</div>
                <div className="inpGroup">
                    <label htmlFor="e-cpswd">Confirm Password*</label>
                    <input type="password" name="cpswd" id='cpswd' placeholder='Your Password Again' onChange={e => setConfirmPassword(e.target.value)} />
                </div>
                <div className="text-danger">{errors.confirmpassword}</div>
                <div className="inpGroup">
                    <label htmlFor="e-cname">Company Name</label>
                    <input type="text" name="e-cname" id="e-cname" placeholder="Company Name" onChange={e => setCompanyname(e.target.value)} />
                </div>
                <div className="inpGroup">
                    <label htmlFor="e-addr">Address</label>
                    <textarea rows="5" name="e-addr" id="e-addr" placeholder="Address" onChange={e => setCompanyAddress(e.target.value)}></textarea>
                </div>
                <div className="bottomSection">
                    &nbsp;
                    <button type="button" className='mainBtn' onClick={addSubmit}>Submit</button>
                </div>
                {/* <div className="row">
                    <div className="col-sm-6"><button className="socialBtn"><img src="/img/google_icon.png" alt="Google" className="img-fluid" /><span>Signup with Google</span></button></div>
                    <div className="col-sm-6"><button className="socialBtn"><img src="/img/linkedin_icon.png" alt="LinkedIn" className="img-fluid" /><span>Signup with LinkedIn</span></button></div>
                </div> */}
                {/* <div>
                    <div className="row">
                        <div className="col-sm-6">
                            <GoogleLogin
                                clientId={process.env.REACT_APP_GOOGLE_SECRET_ID}
                                buttonText="Login with Google"
                                onSuccess={successResponseGoogle}
                                onFailure={errorResponseGoogle}
                                cookiePolicy={'single_host_origin'}
                                className='socialBtn'
                            />
                        </div>
                        <div className="col-sm-6">
                            <LinkedInPage />
                        </div>
                    </div>
                </div> */}
            </form>
        </>
    )
}