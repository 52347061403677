import React from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const steps = [
    {
      number: "1",
      title: "Establish Your Foundation",
      description: "Build secure and scalable microservice ecosystems effortlessly.",
    },
    {
      number: "2",
      title: "Automate Workflows",
      description: "Tailor CI/CD pipelines and operational tasks to your organizational needs.",
    },
    {
      number: "3",
      title: "Enable Auto Scalability",
      description: "Adapt dynamically to workload changes, ensuring high availability and reliability.",
    },
];


const Section4 = () => {

    const navigate = useNavigate();
    
    return (
        <Box
            sx={{
                height: "100%",
                py: 10,
                backgroundColor: "#000000",
                textAlign: "center",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
            }}
        >
            <Typography
                variant="h4"
                sx={{
                    color: "#FAD32E",
                    fontFamily: "Montserrat",
                    fontWeight: "medium",
                    mb: 4,
                    fontSize: "25px",
                }}
            >
                BUILT FOR SCALABLE MICRO SERVICES
            </Typography>
            
            <Typography
                sx={{
                    color: "#FFFFFF",
                    fontFamily: "Montserrat",
                    fontSize: "18px",
                    mb: 6,
                    paddingLeft: "10%",
                    paddingRight: "10%",
                    width: "100%",
                    fontWeight:"medium",
                    marginTop:"-10px"
                }}
            >
                Hashinfra makes microservices management easier enabling businesses adopt modern DevOps methods and reach their full potential.
            </Typography>
            
            <Grid 
                container 
                spacing={4} 
                justifyContent="center"
                sx={{ position: "relative", px: 4 }}
                >
                    {/* Line under the number boxes */}
                    <Box
                        sx={{
                            position: "absolute",
                            top: "55px", // Adjust to align below numbers
                            left: "10%",
                            width: "80%",
                            height: "2px",
                            backgroundImage: "linear-gradient(270deg, #FAD32E, #000000)",
                            borderRadius: "50%",
                            zIndex: 0,
    
                            // Hide line on mobile
                            "@media (max-width: 600px)": {
                                display: "none",
                            }
                        }}
                    />


                {steps.map((step, index) => (
                    <Grid
                        item
                        xs={12}
                        sm={4}
                        key={index}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            textAlign: "center",
                            zIndex:"2"
                        }}
                    >
                        <Box
                            sx={{
                                width: "50px",
                                height: "50px",
                                backgroundColor: "#FAD32E",
                                borderRadius: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                mb: 3,
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{ 
                                    fontWeight: "bold", 
                                    color: "#000000" 
                                }}
                            >
                                {step.number}
                            </Typography>
                        </Box>

                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: "semibold",
                                color: "#FAD32E",
                                mb: 2,
                                fontFamily: "Montserrat",
                                fontSize: "20px",
                            }}
                        >
                            {step.title}
                        </Typography>

                        <Typography
                            variant="body2"
                            sx={{
                                color: "#dcdcdc",
                                maxWidth: "300px",
                                mb: 3,
                                fontFamily: "Montserrat",
                                textAlign: "center",
                            }}
                        >
                            {step.description}
                        </Typography>
                    </Grid>
                ))}
            </Grid>

            <Box mt={4}>
                <Button
                    variant="outlined"
                    sx={{
                        borderColor: "#FAD32E",
                        color: "#FAD32E",
                        fontFamily: "Montserrat",
                        px: 3,
                        py: 1,
                        "&:hover": {
                            backgroundColor: "#FAD32E",
                            color: "#000000",
                        },
                    }}
              onClick={()=> navigate("/form")}
                    
                >
                    See Hashinfra in Action
                </Button>
            </Box>
        </Box>
    );
};

export default Section4;