import React, { useState } from "react";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import http from "../../httpService";
import Signup from "./Signup/Signup";
import Popupz from "../../components/Popupz";
import ForgetPasswordField from "./ForgetPasswordField";
import RecruiterSignup from "./Signup/RecruiterSignup";
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
import { useLinkedIn } from 'react-linkedin-login-oauth2';

function LinkedInPage() {
  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_REACT_CLIEND_ID,
    redirectUri: `${window.location.origin}/linkedin`,
    onSuccess: async (code) => {
      try {
        const formData = new FormData();
        // formData.append('role-id', '8');
        formData.append('code_data', code);
        const response = await http.post('api/linkedinlogin', formData);
        if (response && response.data && response.data.status == 'success') {
          localStorage.setItem('AuthData', JSON.stringify({
            user_name: response.data.user_name,
            auth_token: response.data.access_token,
            auth_role_id: response.data.user_role_id,
            auth_token_type: response.data.token_type,
          }));

          if (response.data.user_role_id == '7') {
            window.location.href = '/my-account-employer';
          } else {
            window.location.href = '/my-account';
          }
        } else {
          this.setState({
            errors: {
              email: response.data.message,
            }
          })
        }
      } catch (exception) {
        if (exception.response && exception.response.data) {
          console.log(exception.response.data);
        }

      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  return (
    <img
      onClick={linkedInLogin}
      src='/img/linkedin.png'
      alt="Sign in with Linked In"
      style={{ maxWidth: '100%', cursor: 'pointer' }}
    />
  );
}

export default class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      phone: "",
      email: undefined,
      password: undefined,
      errors: {
        email: undefined,
        password: undefined,
        message: undefined,
        verifybutton: undefined
      },
    };
  }

  componentDidMount() {
    function start() {
      gapi.client.init({
        clientId: process.env.REACT_APP_GOOGLE_SECRET_ID,
        scope: ""
      })
    }

    gapi.load('client:auth2', start);
  }

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  doLogin = async (event) => {
    event.preventDefault();

    try {
      const response = await http.post('api/login', {
        email: this.state.email,
        password: this.state.password,
      });

      if (response && response.data && response.data.status && response.data.status == 'success') {

        localStorage.setItem('AuthData', JSON.stringify({
          user_name: response.data.user_name,
          auth_token: response.data.access_token,
          auth_role_id: response.data.user_role_id,
          auth_token_type: response.data.token_type,
        }));

        if (response.data.user_role_id == '7') {
          if (response.data.logincount == '1') {
            window.location.href = '/edit-profile-employer';
          } else {
            window.location.href = '/my-account-employer';
          }
        } else {
          if (response.data.logincount == '1') {
            window.location.href = '/edit-profile';
          } else {
            window.location.href = '/my-account';
          }
        }
        /*
        if (response.data.user_role_id == '7') {
          window.location.href = '/my-account-employer';
        } else {
          window.location.href = '/my-account';
        }
        // window.location.href = '/my-account';
        //window.location.href = '/my-account';

        // TODO: go to profile page
      }
      */
      }
    } catch (exception) {
      if (exception.response && exception.response.data && exception.response.data.errors) {
        this.setState({
          errors: {
            email: exception.response.data.errors.email,
            password: exception.response.data.errors.password,
          }
        })
      }
      if (!exception.response.data.errors) {
        this.setState({
          errors: {
            message: exception.response.data.message,
            verifybutton: exception.response.data.verifybutton
          }
        })
      }
    }
  }



  render() {

    const SignUpFunc = () => {
      document.getElementById("login").classList.remove("active");
      document.getElementById("signup").classList.add("active");
      document.querySelectorAll(".tabBtn")[0].classList.remove("active");
      document.querySelectorAll(".tabBtn")[1].classList.add("active");
    }
    const LoginFunc = () => {
      document.getElementById("login").classList.add("active");
      document.getElementById("signup").classList.remove("active");
      document.querySelectorAll(".tabBtn")[0].classList.add("active");
      document.querySelectorAll(".tabBtn")[1].classList.remove("active");
    }
    const TabFunc = (e) => {

      if (!(e.target.classList.contains("active"))) {
        document.querySelector(".tabBtn.active").classList.remove("active");
        document.querySelector(".tabDiv.active").classList.remove("active");
        e.target.classList.add("active");
        var tb = e.target.getAttribute("data-tab-id");
        document.getElementById(tb).classList.add("active");
      }
    }
    const SignUpTab = (e) => {
      console.log(e.target);
      if (!(e.target.classList.contains("active"))) {
        if (document.querySelectorAll(".userRole.active").length > 0) {
          document.querySelector(".userRole.active").classList.remove("active");
          document.querySelector(".signupForm.active").classList.remove("active");
        }
        e.target.classList.add("active");
        var tb = e.target.getAttribute("data-tab-id");
        document.getElementById(tb).classList.add("active");
      }
    }

    const Forgetbutton = (e) => {
      for (var i = 0; i < document.querySelectorAll(".tabBtn").length; i++) {
        document.querySelectorAll(".tabBtn")[i].style.display = "none";
      }
      document.querySelector(".tabDiv.active").classList.remove("active");
      e.target.classList.add("active");
      var tb = e.target.getAttribute("data-tab-id");
      document.getElementById(tb).classList.add("active");
      document.querySelector(".tabBtn.forgetpassword").style.display = "block";
    }

    const VerifyButton = async (e) => {
      try {
        const response = await http.post('api/recendverification', {
          email: this.state.email
        });
        if (response && response.data && response.data.successmessage) {
          let tabbtns = document.querySelector('.glassCard .tabBtns');
          let logintab = document.querySelector('.glassCard #login');
          let signtab = document.querySelector('.glassCard #signup');
          let successmessage = document.querySelector('#topInnerPage #resumeSuccess');
          tabbtns.style.display = 'none';
          logintab.style.display = 'none';
          signtab.style.display = 'none';
          successmessage.style.display = 'block';
          window.scrollTo(0, 0);
        }

      } catch (exception) {
        if (exception.response && exception.response.data && exception.response.data.errors) {
          this.setState({
            errors: {
              email: exception.response.data.errors.email
            }
          })
        }
      }
    }

    const successResponseGoogle = async (response) => {
      if (response.profileObj) {
        const formData = new FormData();
        formData.append('role-id', '8');
        formData.append('profile', JSON.stringify(response.profileObj));
        try {
          const response = await http.post('api/googlesignup', formData);
          if (response && response.data) {
            localStorage.setItem('AuthData', JSON.stringify({
              user_name: response.data.user_name,
              auth_token: response.data.access_token,
              auth_role_id: response.data.user_role_id,
              auth_token_type: response.data.token_type,
            }));

            if (response.data.user_role_id == '7') {
              window.location.href = '/my-account-employer';
            } else {
              window.location.href = '/my-account';
            }
          }
        } catch (exception) {
          if (!exception.response.data.errors) {
            this.setState({
              errors: {
                message: exception.response.data.message
              }
            })
          }
        }
      }
    }

    const errorResponseGoogle = (response) => {
      console.log(response);
    }

    return (
      <main>
        <section className="sectionPad login" id="topInnerPage">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="mainTitle">
                  <h2>Login</h2>
                </div>
              </div>
              <div className="col-md-8 col-lg-6">
                <div className="glassCard">
                  <div className="tabBtns">
                    <button className="tabBtn active" onClick={TabFunc} data-tab-id="login">Login</button>
                    <button className="tabBtn" onClick={TabFunc} data-tab-id="signup">SignUp</button>
                    <button className="tabBtn forgetpassword" style={{ 'display': 'none' }} data-tab-id="forgetpassword">Forgot Password ?</button>
                  </div>
                  <div className="tabDiv active" id="login">
                    <form action="" className="zForm white" onSubmit={this.doLogin}>
                      <div className="d-flex justify-content-between flex-wrap">
                        <div className="text-danger">{this.state.errors.message}</div>
                        <a style={{ 'cursor': 'pointer' }} onClick={VerifyButton} data-tab-id="forgetpassword">{this.state.errors.verifybutton}</a>
                      </div>
                      <div className="inpGroup">
                        <label htmlFor="email">Email</label>
                        <input type="text" name="email" id='email' placeholder='johndoe@email.com' onChange={this.handleInputChange} />
                        <div className="text-danger">{this.state.errors.email}</div>
                      </div>
                      <div className="inpGroup">
                        <label htmlFor="pswd">Password</label>
                        <input type="password" name="password" id='pswd' placeholder='Your Password' onChange={this.handleInputChange} />
                        <div className="text-danger">{this.state.errors.password}</div>
                      </div>
                      <div className="bottomSection">
                        <a style={{ 'cursor': 'pointer' }} onClick={Forgetbutton} data-tab-id="forgetpassword">Forgot Password ?</a>
                        <button type="submit" className='mainBtn'>Login</button>
                      </div>
                      {/* <div className="row">
                            <div className="col-sm-6"><button className="socialBtn"><img src="/img/google_icon.png" alt="Google" className="img-fluid" /><span>Login with Google</span></button></div>
                            <div className="col-sm-6"><button className="socialBtn"><img src="/img/linkedin_icon.png" alt="LinkedIn" className="img-fluid" /><span>Login with LinkedIn</span></button></div>
                          </div> */}
                      <div className="row">
                        <div className="col-sm-6 position-relative">
                          <div className="socialBtn absolute-fill"><img src="img/google_icon.png" alt="google-icon" /><span>Login with Google</span></div>
                          <div className="hideBtn">
                          <GoogleLogin
                            clientId={process.env.REACT_APP_GOOGLE_SECRET_ID}
                            buttonText="Login with Google"
                            onSuccess={successResponseGoogle}
                            onFailure={errorResponseGoogle}
                            cookiePolicy={'single_host_origin'}
                            className='socialBtn'
                          />
                          </div>
                        </div>
                        <div className="col-sm-6 position-relative">
                          <div className="socialBtn absolute-fill"><img src="img/linkedin_icon.png" alt="google-icon" /><span>Login with LinkedIn</span></div>
                          <div className="hideBtn"><LinkedInPage /></div>
                        </div>
                      </div>
                    </form>
                    <p className="signupText">Don't have an account? <button className='loginLink' onClick={SignUpFunc}>SignUp</button></p>
                  </div>
                  <div className="tabDiv" id="forgetpassword">
                    <ForgetPasswordField />
                  </div>
                  <div className="tabDiv" id="signup">
                    <div className="inpGroup">
                      <label>Are you</label>
                      <ul className='areYou'>
                        <li><input type="radio" name="userRole" id="candidate" /><label className='userRole' data-tab-id='candidateForm' htmlFor="candidate" onClick={SignUpTab}><i className="bi bi-check2"></i> Looking for a Job</label></li>
                        <li><input type="radio" name="userRole" id="employer" /><label className='userRole' data-tab-id='employerForm' htmlFor="employer" onClick={SignUpTab}><i className="bi bi-check2"></i> Looking to Hire</label></li>
                      </ul>
                    </div>
                    <div className="signupForm" id="candidateForm">
                      <Signup />
                      {/* <form action="" className="zForm white">
                          <div className="inpGroup">
                            <label htmlFor="fname">Firstname*</label>
                            <input type="text" name="fname" id='fname' placeholder='John' required/>
                          </div>
                          <div className="inpGroup">
                            <label htmlFor="lname">Lastname*</label>
                            <input type="text" name="lname" id='lname' placeholder='Doe' required/>
                          </div>
                          <div className="inpGroup">
                            <label htmlFor="phone">Phone*</label>
                            <PhoneInput placeholder="+91 XXXXX-XXXXX" required/>
                          </div>
                          <div className="inpGroup">
                            <label htmlFor="email">Email*</label>
                            <input type="text" name="email" id='email' placeholder='johndoe@email.com' required/>
                          </div>
                          <div className="inpGroup">
                            <label htmlFor="pswd">Password*</label>
                            <input type="text" name="pswd" id='pswd' placeholder='Your Password' required/>
                          </div>
                          <div className="inpGroup">
                            <label htmlFor="cpswd">Confirm Password*</label>
                            <input type="text" name="cpswd" id='cpswd' placeholder='Your Password Again' required/>
                          </div>
                          <div className="inpGroup">
                                <label htmlFor="fileResume">Upload Resume (optional)</label>
                                <input name="fileResume" type="file"/>
                            </div>
                          <div className="bottomSection">
                          &nbsp;
                          <button type="submit" className='mainBtn'>Submit</button>
                        </div>
                          <div className="row">
                            <div className="col-sm-6"><button className="socialBtn"><img src="/img/google_icon.png" alt="Google" className="img-fluid" /><span>Signup with Google</span></button></div>
                            <div className="col-sm-6"><button className="socialBtn"><img src="/img/linkedin_icon.png" alt="LinkedIn" className="img-fluid" /><span>Signup with LinkedIn</span></button></div>
                          </div>
                        </form> */}
                      <p className="signupText">Already have an account? <button className='loginLink' onClick={LoginFunc}>Login</button></p>
                    </div>
                    <div className="signupForm" id="employerForm">
                      <RecruiterSignup />
                      {/*
                      <form action="" className="zForm white">
                        <div className="inpGroup">
                          <label htmlFor="e-fname">Firstname*</label>
                          <input type="text" name="fname" id='fname' placeholder='John' required />
                        </div>
                        <div className="inpGroup">
                          <label htmlFor="e-lname">Lastname*</label>
                          <input type="text" name="lname" id='lname' placeholder='Doe' required />
                        </div>
                        <div className="inpGroup">
                          <label htmlFor="e-phone">Phone*</label>
                          <PhoneInput placeholder="+91 XXXXX-XXXXX" required />
                        </div>
                        <div className="inpGroup">
                          <label htmlFor="e-email">Email*</label>
                          <input type="text" name="email" id='email' placeholder='johndoe@email.com' required />
                        </div>
                        <div className="inpGroup">
                          <label htmlFor="e-pswd">Password*</label>
                          <input type="text" name="pswd" id='pswd' placeholder='Your Password' required />
                        </div>
                        <div className="inpGroup">
                          <label htmlFor="e-cpswd">Confirm Password*</label>
                          <input type="text" name="cpswd" id='cpswd' placeholder='Your Password Again' required />
                        </div>
                        <div className="inpGroup">
                          <label htmlFor="e-cname">Company Name</label>
                          <input type="text" name="e-cname" id="e-cname" placeholder="Company Name" required />
                        </div>
                        <div className="inpGroup">
                          <label htmlFor="e-addr">Address</label>
                          <textarea rows="5" name="e-addr" id="e-addr" placeholder="Address" required></textarea>
                        </div>
                        <div className="bottomSection">
                          &nbsp;
                          <button type="submit" className='mainBtn'>Submit</button>
                        </div>
                        <div className="row">
                          <div className="col-sm-6"><button className="socialBtn"><img src="/img/google_icon.png" alt="Google" className="img-fluid" /><span>Signup with Google</span></button></div>
                          <div className="col-sm-6"><button className="socialBtn"><img src="/img/linkedin_icon.png" alt="LinkedIn" className="img-fluid" /><span>Signup with LinkedIn</span></button></div>
                        </div>
                      </form> */}
                      <p className="signupText">Already have an account? <button className='loginLink' onClick={LoginFunc}>Login</button></p>
                    </div>
                  </div>
                  <div className="tabDiv" id="resumeSuccess">
                    <div className="text-center">
                      <svg className='img-fluid mb-2' xmlns="http://www.w3.org/2000/svg" width="113.625" height="113.133" viewBox="0 0 113.625 113.133">
                        <g id="Icon_feather-check-circle" data-name="Icon feather-check-circle" transform="translate(-1.49 -1.43)" stroke='#00ff2a'>
                          <path id="Path_1341" data-name="Path 1341" d="M112.994,52.962v5.06A55,55,0,1,1,80.381,7.755" transform="translate(0 0)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                          <path id="Path_1342" data-name="Path 1342" d="M85,6,30,61.052l-16.5-16.5" transform="translate(27.998 8.024)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                        </g>
                      </svg>
                      <p>Link has been sent your Mail</p>
                      <a href="/" className='mainBtn'>Go to Home</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main >
    );
  }
};