import React, { useState } from 'react'
import PhoneInput from 'react-phone-input-2';
import http from '../../httpService';

const Popupz = (props) => {
    return (
        <>
            <div className={"bgOverlay " + props.className} id={props.id}>
                <div className="popupCard">
                    <button className="closeBtn" onClick={() => props.closePopup()}><i className="bi bi-x-lg"></i></button>
                    <h2 className="popupHead">{props.title}</h2>
                    <div className="maxHeight">
                        {props.children}
                    </div>
                </div>
            </div>
        </>
    )
}

const Popupz1 = (props) => {
    return (
        <>
            <div className={"bgOverlay big " + props.className} id={props.id}>
                <div className="popupCard big">
                    <button className="closeBtn" onClick={() => props.closePopup1()}><i className="bi bi-x-lg"></i></button>
                    <h2 className="popupHead">{props.title}</h2>
                    <div className="maxHeight">
                        {props.children}
                    </div>
                </div>
            </div>
        </>
    )
}

const Landing = () => {
    const [message, setMessage] = useState(null);
    const [email, setEmail] = useState("");
    const [fullname, setFullname] = useState("");
    const [phone, setPhone] = useState("");
    const [company, setCompany] = useState("");
    const [notificationEmail, setNotificationEmail] = useState("");
    const [showPopup, setShowPopup] = useState("");
    const [showPopup1, setShowPopup1] = useState("");
    const [showTitle, setShowTitle] = useState("");

    const [error, setError] = useState({
        email: null,
        fullname: null,
        phone: null
    });
    const [notificationError, setNotificationError] = useState({
        email: null
    });
    const Save = async (event) => {
        event.preventDefault();
        try {
            const formData = new FormData();
            formData.append('fullname', fullname);
            formData.append('email', email);
            formData.append('phone', phone);
            formData.append('company', company);

            const response = await http.post('api/landing-store', formData);
            if (response.data) {
                setShowTitle("Success");
                setMessage(response.data.message);
                setEmail("");
                setFullname("");
                setPhone("+91");
                setCompany("");
                setError("");
                setShowPopup("active");
            }
        } catch (exception) {
            if (exception.response && exception.response.data) {
                setError(exception.response.data);
            }
        }
    }

    const SaveNew = async (event) => {
        event.preventDefault();
        try {
            const formData = new FormData();
            formData.append('fullname', fullname);
            formData.append('email', email);
            formData.append('phone', phone);        
            formData.append('company', company);
            const response = await http.post('api/landing-store', formData);
            if (response.data) {
                setShowTitle("Thank you for booking an appointment with ClockHash Technologies");
                setMessage(<><p>We're excited to learn more about your IT needs and how we can help you achieve your goals.</p> <p>One of our team members will be in touch soon to confirm your appointment and schedule a time to talk.</p> <br/> <p>We look forward to hearing from you soon!</p></>);
                setEmail("");
                setFullname("");
                setPhone("+91");
                setCompany("");
                setError("");
                setShowPopup1("active");
            }
        } catch (exception) {
            if (exception.response && exception.response.data) {
                setError(exception.response.data);
            }
        }
    }

    const SaveNotification = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('email', notificationEmail);
        try {
            const response = await http.post('api/email-subscribe', formData);
            if (response.data) {
                setShowTitle("Successfully Subscribed!");
                setMessage("We will notify you when a new position opens up.");
                setNotificationEmail("");
                setShowPopup("active");
                setNotificationError("");
            }
        } catch (exception) {
            if (exception.response && exception.response.data) {
                setNotificationError(exception.response.data);
            }
        }
    }
    return (
        <>
            <main>
                <section id="landingBanner">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7">
                                <h2>Book an Appointment with ClockHash Technologies</h2>
                                {/* <h1>Your Next Hire is Waiting!</h1>
                                <h3>Reach to us and get a Free consultation and demo.</h3> */}
                                <h4 className='normalHead'>Get in touch with our team to discuss your IT needs and schedule a time to talk.</h4>
                                <form className='zForm white' onSubmit={SaveNew}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="inpGroup">
                                                <label>Request Consultation</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="inpGroup">
                                                <label htmlFor="fname">Fullname</label>
                                                <input type="text" name="fname" id="fname" placeholder='John Doe' onChange={(e) => setFullname(e.target.value)} value={fullname} />
                                                <div className="text-danger">{error.fullname ? error.fullname : ""}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="inpGroup">
                                                <label>Phone(optional)</label>
                                                <PhoneInput country={'in'} name="work_phone" onChange={(value, data, event, formattedValue) => setPhone(event.target.value)} value={phone} />
                                                <div className="text-danger">{error.phone ? error.phone : ""}</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="inpGroup">
                                                <label htmlFor="email">Email</label>
                                                <input type="email" name="email" id="email" placeholder='johndoe@email.com' onChange={(e) => setEmail(e.target.value)} value={email} />
                                                <div className="text-danger">{error.email ? error.email : ""}</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="inpGroup">
                                                <label htmlFor="company">Company Name</label>
                                                <input type="text" name="company" id="company" onChange={(e) => setCompany(e.target.value)} value={company} />
                                                <div className="text-danger">{error.company ? error.company : ""}</div>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-4">
                                            <h5 className='normalHead'>We look forward to hearing from you!</h5>
                                            <button type="submit" className='mainBtn'>Book Appointment</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-5">
                                <img src="/img/group-people-working-team.jpg" alt="banner" className="img-fluid rounded-circle" />
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section id="whyClockhash" className='sectionPad'>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10 col-lg-8">
                                <div className="mainTitle"><h2>Why Companies Choose Clockhash?</h2></div>
                                <div className="row">
                                    <div className="col-md-4 col-sm-6">
                                        <div className="whycCard">
                                            <img src="/img/landing-page/trusted-expert-only.svg" alt="why icon" />
                                            <h5>Trusted Experts Only</h5>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                        <div className="whycCard">
                                            <img src="/img/landing-page/the-right-fit.svg" alt="why icon" />
                                            <h5>The Right Fit</h5>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                        <div className="whycCard">
                                            <img src="/img/landing-page/scale-as-needed.svg" alt="why icon" />
                                            <h5>Scale as Needed</h5>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                        <div className="whycCard">
                                            <img src="/img/landing-page/seamless-hiring.svg" alt="why icon" />
                                            <h5>Seamless Hiring</h5>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                        <div className="whycCard">
                                            <img src="/img/landing-page/flexible-enagements.svg" alt="why icon" />
                                            <h5>Flexible Engagements</h5>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                        <div className="whycCard">
                                            <img src="/img/landing-page/expert-talent-matching.svg" alt="why icon" />
                                            <h5>Expert Talent Matching</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src="/img/landing-page/question-mark.svg" alt="question mark" className='questionDesign' />
                </section> */}
                <section className="sectionPad" id="notification">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-5">
                                <img src="/img/landing-page/notification.png" alt="notification" className='img-fluid' />
                            </div>
                            <div className="col-md-7">
                                <h2>Be in the know.</h2>
                                <h5>Get notified when a new position opens up.</h5>
                                <form className='zForm white' onSubmit={SaveNotification}>
                                    <div className="inpGroup">
                                        <label htmlFor="n-email">Email</label>
                                        <input type="email" name="n-email" id="n-email" placeholder='johndoe@gmail.com' value={notificationEmail} onChange={(e) => setNotificationEmail(e.target.value)} />
                                        <div className="text-danger">{notificationError.email ? notificationError.email : ""}</div>
                                    </div>
                                    <button type="submit" className='mainBtn'>Notify</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Popupz title={showTitle} id="coverSuccess" className={showPopup} closePopup={() => setShowPopup("")}>
                <p>{message}</p>
                <a className='mainBtn' type='button' onClick={() => { setShowPopup(""); }}>Close</a>
            </Popupz>

            <Popupz1 title={showTitle} id="coverThankyou" className={showPopup1} closePopup1={() => setShowPopup1("")}>
                <p>{message}</p>
                {/* <a className='mainBtn' type='button' onClick={() => { setShowPopup1(""); }}>Closee</a> */}
                <a href="/" className="mainBtn">Back to Home</a>
            </Popupz1>
        </>
    )
}

export default Landing