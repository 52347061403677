import React, { useState } from 'react';
import http from '../httpService';

const Popupz = (props) => {
    return (
        <>
            <div className={"bgOverlay " + props.className} id={props.id}>
                <div className="popupCard">
                    <button className="closeBtn" onClick={() => props.closePopup()}><i className="bi bi-x-lg"></i></button>
                    <h2 className="popupHead">{props.title}</h2>
                    <div className="maxHeight">
                        {props.children}
                    </div>
                </div>
            </div>
        </>
    )
}

const Footer = () => {
    const [email, setEmail] = useState("");
    const [showPopup, setShowPopup] = useState("");
    const [message, setMessage] = useState(null);
    const [error, setError] = useState({
        email: null
    });
    const Save = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('email', email);
        try {
            const response = await http.post('api/email-subscribe', formData);
            if (response.data) {
                setMessage("Successfully Subscribed!");
                setEmail("");
                setError("");
                setShowPopup("active");
            }
        } catch (exception) {
            if (exception.response && exception.response.data) {
                setError(exception.response.data);
            }
        }
    }
    return (
        <>
            <footer>
                <div className="topFooter">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 col-lg-6">
                                <img src="/img/logo-small.svg" alt="logo" className="img-fluid" />
                                <p>Get our tips, special offers and updates straight into your inbox</p>
                                <form action="" className="subscribe" onSubmit={Save} style={{ marginBottom: error.email ? "0px" : "25px" }}>
                                    <input type="email" name="mail" id="mail" placeholder="johndoe@email.com" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    <button type="submit">Send <i className="fas fa-paper-plane"></i></button>
                                </form>
                                <div className="text-danger">{error.email ? error.email : ""}</div>
                                <ul className="conDetails">
                                    <li>
                                        <i className="fas fa-map-marker-alt"></i>
                                        <div className="addressFooter w-100">
                                            <p>India <span>|</span> Netherlands <span>|</span> UAE</p>
                                            {/* {/* <p>503, 5th Floor, Trans Asian Corporate Park,<br/>
                                    Seaport Airport Road,<br/>
                                    Chittethukkara, Kakkanad<br/>
                                    Cochin - 682 037<br/>
                                    Kerala</p>
                                    <div className="line"></div>
                                    <p>503, 5th Floor, Trans Asian Corporate Park,<br/>
                                    Seaport Airport Road,<br/>
                                    Chittethukkara, Kakkanad<br/>
                                    Cochin - 682 037<br/>
                                    Kerala</p> */}
                                    <div className="line"></div>
                                    <p>L-11 A, Nippon Q1 Mall,<br/>
                                    NH 66 Bypass,<br/>
                                    Vennala P O,<br/>
                                    Ernakulam - 682028<br/>
                                    Kerala</p>
                                        </div> 
                                    </li>
                                    <li>
                                        <i className="fas fa-envelope"></i>
                                        <a href="mailto:info@clockhash.com">info@clockhash.com</a>
                                    </li>
                                    {/* <li>
                                <i className="fas fa-phone-alt"></i>
                                <a href="tel:+91 XXXX XXXX XX">+91 XXXX XXXX XX</a>
                            </li> */}
                                </ul>
                                <ul className="socialList">
                                    <li><a target="_blank" href="https://www.facebook.com/ClockHash-Technologies-100511448836722"><i className="fab fa-facebook-f"></i></a></li>
                                    <li><a target="_blank" href="https://www.instagram.com/clockhash/"><i className="fab fa-instagram"></i></a></li>
                                    <li><a target="_blank" href="https://www.linkedin.com/company/clockhash/"><i className="fab fa-linkedin-in"></i></a></li>
                                </ul>
                            </div>
                            <div className="col-md-8 col-lg-6">
                                <div className="listLinks">
                                    <div className="footerList">
                                        <h3>Our Services</h3>
                                        <ul>
                                            <li>Cloud Services</li>
                                            <li>DevOps</li>
                                            <li>Embedded System</li>
                                            <li>IoT</li>
                                            <li>Mobile Applications</li>
                                            <li>Web based applications</li>
                                            <li>QA Testing</li>
                                            <li>Cyber Security</li>
                                            <li>Onsite Consultancy</li>
                                        </ul>
                                    </div>
                                    <div className="vLine"></div>
                                    <div className="footerList">
                                        <h3>Quick Links</h3>
                                        <ul>
                                            <li><a href="/">Home</a></li>
                                            {/* <li><a href="/">Hire from us</a></li> */}
                                            <li><a href="/about-us">About us</a></li>
                                            <li><a href="/why-us">Why us</a></li>
                                            <li><a href="/contact">Contact</a></li>
                                            <li><a href="/careers">Careers</a></li>
                                            <li><a href='/cookie-policy'>Cookie Policy</a></li>
                                            <li><a href='/privacy-policy'>Privacy Policy</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottomFooter">
                    <p>© Copyright {(new Date().getFullYear())} ClockHash. All Rights Reserved | Powered by H2bonza Infotechnologies</p>
                </div>
                {/* <div className='whatsapp'>
                    <a href='http://wa.me/+353899630054' target={'_blank'}>
                        <i className="fab fa-whatsapp" aria-hidden="true"></i>
                        <span>Talk to sales</span>
                    </a>
                </div> */}
            </footer>
            <Popupz title="Success" id="coverSuccess" className={showPopup} closePopup={() => setShowPopup("")}>
                <p>{message}</p>
                <a className='mainBtn' type='button' onClick={() => { setShowPopup(""); }}>Close</a>
            </Popupz>
        </>
    )
};

export default Footer;