import React from 'react';
import {
	Routes,
	Route,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Hashinfra from '../views/pages/Hashstack';
import Autocicd from '../views/pages/AutoCiCd';
import Cloudash from '../views/pages/Cloudash';
import HomePage from "../views/pages/HomePage";
import Header from './Header';
import Footer from './Footer';
import Form from '../views/pages/Form';
import Home from '../views/pages/Home';
import AboutUs from '../views/pages/AboutUs';
import Contact from '../views/pages/Contact';
import WhyUs from '../views/pages/WhyUs';
import Career from '../views/Career/Index';
import CareerDetails from '../views/Career/Details';
import BlogOne from '../views/pages/blog/BlogOne';
import BlogTwo from '../views/pages/blog/BlogTwo';
import BlogThree from '../views/pages/blog/BlogThree';
import CookieConsent from 'react-cookie-consent';
import CookiePolicy from '../views/pages/CookiePolicy';
import Login from '../views/pages/Login';
import MyAccountCandidate from '../views/pages/MyAccountCandidate';
import MyAccountEmployer from '../views/pages/MyAccountEmployer';
import EditProfileCandidate from '../views/pages/EditProfileCandidate';
import EditProfileEmployer from '../views/pages/EditProfileEmployer';
import MailVerification from '../views/pages/MailVerification';
import PrivacyPolicy from '../views/pages/PrivacyPolicy';
import PostJob from '../views/pages/PostJob'
import EditJob from '../views/pages/EditJob';
import ErrorPage from '../views/pages/ErrorPage';
import HireFromUs from '../views/pages/HireFromUs';
import BlogAll from './BlogAll';
import { BlogPage } from '../views/pages/blog/BlogPage';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import DivideBanner from './DivideBanner';
import Landing from '../views/pages/Landing'
// import Devops from '../views/pages/careers/Devops';
// import FullStackJava from '../views/pages/careers/FullStackJava';
// import FullStackRuby from '../views/pages/careers/FullStackRuby';
// import JuniorAnalyst from '../views/pages/careers/JuniorAnalyst';
// import Python from '../views/pages/careers/Python';

const Layout = () => {

    let isLoggedIn = false;

    let authData = localStorage.getItem('AuthData');

    if (authData) {
        authData = JSON.parse(authData);
        if (authData) {

            isLoggedIn = true
        }
    }

    return (
        <>

            <Header />

            <Routes>
                <Route exact path='/why-us' element={<WhyUs />}></Route>
                <Route exact path="/contact" element={<Contact />}></Route>
                <Route exact path="/about-us" element={<AboutUs />}></Route>
                <Route exact path="" element={<Home />}></Route>`
                <Route exact path="/hashinfra" element={<Hashinfra />}></Route>
                <Route exact path="/form" element={<Form />}></Route>
                <Route exact path="/autocicd" element={<Autocicd />}></Route>
                <Route exact path="/cloudash" element={<Cloudash />}></Route>
                <Route exact path='/verify-mail' element={<MailVerification/>}></Route>
                            <Route
                    exact
                    path="/homepage"
                    element={<HomePage />}
                    ></Route>
                <Route exact path='/cookie-policy' element={<CookiePolicy/>}></Route>
                <Route exact path='/privacy-policy' element={<PrivacyPolicy/>}></Route>
                <Route exact path='/careers' element={<Career />}></Route>
                <Route exact path='/careers/:vacancySlug' element={<CareerDetails />}></Route>
                <Route exact path='/login' element={<Login/>}></Route>
                <Route exact path='/my-account' element={isLoggedIn ? <MyAccountCandidate/> : <Login/>}></Route>
                <Route exact path='/my-account-employer' element={isLoggedIn ? <MyAccountEmployer/> : <Login/>}></Route>
                <Route exact path='/edit-profile' element={isLoggedIn ? <EditProfileCandidate/> : <Login/>}></Route>
                <Route exact path='/edit-profile-employer' element={isLoggedIn ? <EditProfileEmployer/> : <Login/>}></Route>
                <Route exact path='/post-jobs' element={<PostJob/>}></Route>
                <Route exact path='/edit-job/:id' element={<EditJob/>}></Route>
                <Route exact path="/hire" element={<HireFromUs/>}></Route>
                <Route exact path="/linkedin" element={<LinkedInCallback />}></Route>

            {/* <Route exact path='/career-details/python-developer' element={<Python />}></Route>
            <Route exact path='/career-details/fullstack-ruby' element={<FullStackRuby />}></Route>
            <Route exact path='/career-details/devops-engineer' element={<Devops />}></Route>
            <Route exact path='/career-details/fullstack-javascript' element={<FullStackJava />}></Route>
            <Route exact path='/career-details/junior-analyst' element={<JuniorAnalyst />}></Route> */}

            <Route exact path='/blog/why_hire_global_talent' element={<BlogOne />}></Route>
            <Route exact path='/blog/top_trending_technologies' element={<BlogTwo />}></Route>
            <Route exact path='/blog/how-hiring' element={<BlogThree />}></Route>
            <Route exact path="/blog" element={<BlogAll/>}></Route>
            <Route exact path='/blog/:slug' element={<BlogPage />}></Route>
            <Route exact path='/your_trusted_cloud_solutions_partner' element={<Landing />}></Route>

            <Route path='*' element={<ErrorPage/>}></Route>
            </Routes>
            <Footer />
            <DivideBanner/>
            <CookieConsent>By continuing to use this site you agree to our <a href="/cookie-policy">Cookie Policy</a>.</CookieConsent>
            <ToastContainer
            />
        </>
    )
};

export default Layout;
