import React from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import bgimage from "../Images/cloudashbg2.png";

const features = [
  {
    title: "Optimal VM Selection",
    description: "Cloudash ensures that you deploy VMs that are perfectly aligned with your computational requirements and budget.",
  },
  {
    title: "Effortless Launch",
    description: "With a single click, you can be up and running on one of the most budget-friendly VMs available, saving you valuable time and resources.",
  },
  {
    title: "Custom Configuration Pipelines",
    description: "Enter your desired CPU and RAM specifications, include your SSA key, and initiate the launch process seamlessly.",
  },
];

const Page2 = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${bgimage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        px: { xs: 3, sm: 4, md: 6 },
        textAlign: "center",
        width: "100vw",
        maxWidth: "100%",
        py: 6,
      }}
    >
      <Typography variant="h3" sx={{fontFamily:'Montserrat', color: "#fff", fontWeight: "bold", fontSize: { xs: "28px", sm: "32px", md: "36px" }, mb: 4 }}>
      Revolutionizing VM Deployment
        <Box sx={{ width: "288px", height: "8px", backgroundColor: "#FAD32E", margin: "12px auto 0 auto", borderRadius: "2px" }} />
      </Typography>

      <Box sx={{ backgroundColor: "rgba(51, 68, 93, 0.8)", color: "#fff", padding: "24px", borderRadius: "10px", textAlign: "center", maxWidth: "1000px", mb: 5 }}>
        <Typography variant="body1" sx={{ fontFamily:'Montserrat',fontSize: { xs: "14px", sm: "16px", md: "18px" } }}>
        Cloudash, your ultimate solution for seamless and cost-effective virtual machine (VM) deployment on leading cloud providers such as AWS and Azure. We've revolutionized the way you select and launch VMs by integrating real-time pricing data, making your cloud experience smarter and more efficient.
         </Typography>
      </Box>

      <Typography variant="h5" sx={{ fontFamily:'Montserrat',color: "#fff", fontWeight: "bold", fontSize: { xs: "20px", sm: "24px", md: "26px" }, mb: 2 }}>
        Key Features
        <Box sx={{ width: "80px", height: "4px", backgroundColor: "#FAD32E", margin: "8px auto 0 auto", borderRadius: "2px" }} />
      </Typography>

      <Grid container spacing={3} justifyContent="center" sx={{ maxWidth: "1300px", mt: 3, px: 2 }}>
        {features.map((feature, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Box sx={{ backgroundColor: "rgba(51, 68, 93, 0.8)", color: "#fff", padding: "20px", borderRadius: "10px", textAlign: "center", minHeight: "250px" }}>
              <Typography variant="h6" sx={{ fontFamily:'Montserrat',fontWeight: "bold", color: "white", fontSize: { xs: "18px", sm: "20px" }, mb: 1 ,textAlign:'left'}}>
                {feature.title}
              </Typography>
              <Typography variant="body2" sx={{ fontSize: { xs: "14px", sm: "16px",textAlign:'left' } }}>
                {feature.description}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>

      <Typography variant="h5" sx={{fontFamily:'Montserrat', color: "#fff", fontWeight: "bold", fontSize: { xs: "26px", sm: "30px", md: "34px" }, mt: 6, mb: 2 }}>
      Ready to Optimize Your VM Deployments?
        <Box sx={{ width: "80px", height: "4px", backgroundColor: "#FAD32E", margin: "8px auto 0 auto", borderRadius: "2px" }} />
      </Typography>
      
      <Box sx={{ backgroundColor: "rgba(51, 68, 93, 0.8)", color: "#fff", padding: "24px", borderRadius: "10px", textAlign: "center", maxWidth: "1000px", mb: 5 }}>
        <Typography variant="body1" sx={{ fontFamily:'Montserrat',fontSize: { xs: "14px", sm: "16px" } }}>
        Experience the future of VM deployment with Cloudash. Join us to unlock a world of efficient, economical, and effortless VM deployments. Revolutionize your cloud experience with Cloudash.
         </Typography>
      </Box>

      {/* <Button variant="contained" sx={{fontFamily:'Montserrat', backgroundColor: "#FAD32E", color: "#000", fontWeight: "bold", fontSize: { xs: "14px", sm: "16px" }, px: { xs: 3, sm: 4 }, py: { xs: 1.5, sm: 2 }, borderRadius: "8px", width: { xs: "80%", sm: "auto" }, "&:hover": { backgroundColor: "black",color:'#FAD32E',borderColor:'#FAD32E' } }}>
      REGISTER NOW
      </Button> */}
    </Box>
  );
};

export default Page2;
