import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import http from '../../../httpService';
import PhoneInput from 'react-phone-input-2';
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
// import { useLinkedIn } from 'react-linkedin-login-oauth2';

/*function LinkedInPage() {

    const { linkedInLogin } = useLinkedIn({
        clientId: process.env.REACT_APP_REACT_CLIEND_ID,
        redirectUri: `${window.location.origin}/linkedin`,
        onSuccess: async (code) => {
            try {
                const formData = new FormData();
                formData.append('role-id', '8');
                formData.append('code_data', code);
                const response = await http.post('api/jobseekerlinkedinlogin', formData);
                if (response && response.data) {
                    localStorage.setItem('AuthData', JSON.stringify({
                        user_name: response.data.user_name,
                        auth_token: response.data.access_token,
                        auth_role_id: response.data.user_role_id,
                        auth_token_type: response.data.token_type,
                    }));

                    if (response.data.user_role_id == '7') {
                        window.location.href = '/my-account-employer';
                    } else {
                        window.location.href = '/my-account';
                    }
                }
            } catch (exception) {
                if (exception.response && exception.response.data) {
                    console.log(exception.response.data);
                }

            }
        },
        onError: (error) => {
            console.log(error);
        },
    });

    return (
        <img
            onClick={linkedInLogin}
            src='/img/linkedin.png'
            alt="Sign in with Linked In"
            style={{ maxWidth: '100%', cursor: 'pointer' }}
        />
    );
}*/

export default function Signup() {

    const [phoneCountryCode, setPhoneCountryCode] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [resume, setResume] = useState('');
    const [resumeKey, setResumeKey] = useState(1);
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [confirmpassword, setConfirmPassword] = useState('');
    const [savingResume, setSavingResume] = useState(false);
    const [errors, setErrors] = useState({
        firstname: undefined,
        lastname: undefined,
        email: undefined,
        resume: undefined,
        password: undefined,
        password_confirmation: undefined,
        phone: undefined
    });

    useEffect(() => {
        function start() {
            gapi.client.init({
                clientId: process.env.REACT_APP_GOOGLE_SECRET_ID,
                scope: ""
            })
        }

        gapi.load('client:auth2', start);
    });

    const handleOnChangework = (value, data, event, formattedValue) => {
        setPhone(event.target.value);
    }

    const successResponseGoogle = async (response) => {
        if (response.profileObj) {
            const formData = new FormData();
            formData.append('role-id', '8');
            formData.append('profile', JSON.stringify(response.profileObj));
            try {
                const response = await http.post('api/googlesignup', formData);
                if (response && response.data) {
                    localStorage.setItem('AuthData', JSON.stringify({
                        user_name: response.data.user_name,
                        auth_token: response.data.access_token,
                        auth_role_id: response.data.user_role_id,
                        auth_token_type: response.data.token_type,
                    }));

                    if (response.data.user_role_id == '7') {
                        window.location.href = '/my-account-employer';
                    } else {
                        window.location.href = '/my-account';
                    }
                }
            } catch (exception) {
                if (exception.response && exception.response.data) {
                    console.log(exception.response.data);
                }
            }
        }
    }

    const errorResponseGoogle = (response) => {
        console.log(response);
    }

    const addResume = async () => {
        setSavingResume(true);
        let errors = {};
        let isValid = true;
        /*
        if (firstName == '') {
            isValid = false;
            errors.firstname = "Please enter your name.";
        }

        if (lastName == '') {
            isValid = false;
            errors.lastname = "Please enter your name.";
        }

        if (email == '') {
            isValid = false;
            errors.email = "Please enter your email.";
        } else {
            let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(email)) {
                isValid = false;
                errors.email = "Please enter valid email address.";
            }
        }
        */

        if (!resume) {
            // isValid = false;
            // errors.resume = "Please upload your resume.";
        } else {
            var extension = resume.name.split('.').pop();
            if (!["pdf", "docx", "doc"].includes(extension.toString().toLowerCase())) {
                isValid = false;
                errors.resume = "Only PDF/DOCX/DOC files are allowed.";
            }
        }

        if (isValid) {

            const formData = new FormData();
            // formData.append('jobs_type', '2');
            formData.append('firstname', firstName);
            formData.append('lastname', lastName);
            formData.append('phone', phone);
            formData.append('email', email);
            formData.append('password', password);
            formData.append('password_confirmation', confirmpassword);
            //formData.append('phone', phone);
            formData.append('resume', resume);

            /*
            if (props.vacancyId) {
                formData.append('vacancy_id', props.vacancyId);
            }
            */
            try {
                let response = await http.post("api/register/job-seeker", formData);
                // setErrors(errors);
                // setFirstName('');
                // setLastName('');
                // setEmail('');
                // setPhone('');
                // setResume('');
                // setPassword('');
                // setConfirmPassword('');
                // setResumeKey(resumeKey + 1);
                // setSavingResume(false);

                ReactGA.event({
                    category: "Submit Resume",
                    action: "User submitted resume",
                });

                if (response && response.data && response.data.status && response.data.status == 'success') {

                    let tabbtns = document.querySelector('.glassCard .tabBtns');
                    let logintab = document.querySelector('.glassCard #login');
                    let signtab = document.querySelector('.glassCard #signup');
                    let successmessage = document.querySelector('#topInnerPage #resumeSuccess');
                    tabbtns.style.display = 'none';
                    logintab.style.display = 'none';
                    signtab.style.display = 'none';
                    successmessage.style.display = 'block';
                    window.scrollTo(0, 0);

                    setFirstName('');
                    setLastName('');
                    setEmail('');
                    setPhone('');
                    setResume('');
                    setPassword('');
                    setConfirmPassword('');
                    setResumeKey(resumeKey + 1);
                    setSavingResume(false);

                    /*  window.location.href = '/login'; */

                    // TODO: go to profile page
                }

                /*.then(response => {
                    props.onClose();
                    setErrors(errors);
                    setFirstName('');
                    setLastName('');
                    setEmail('');
                    setPhone('');
                    setResume('');
                    setResumeKey(resumeKey + 1);
                    setSavingResume(false);
                });*/
            } catch (exception) {

                if (exception.response && exception.response.data && exception.response.data.errors) {

                    setErrors(exception.response.data.errors);
                    setSavingResume(false);
                }
            }


        } else {
            setErrors(errors);
            setSavingResume(false);
        }
    }
        /*upload resume file input name showing function*/
        ; (function (document, window, index) {
            var inputs = document.querySelectorAll('.inputfile');
            Array.prototype.forEach.call(inputs, function (input) {
                var label = input.nextElementSibling,
                    labelVal = label.innerHTML;

                input.addEventListener('change', function (e) {
                    var fileName = '';
                    if (this.files && this.files.length > 1)
                        fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
                    else
                        fileName = e.target.value.split('\\').pop();

                    if (fileName)
                        label.querySelector('span').innerHTML = fileName;
                    else
                        label.innerHTML = labelVal;
                });

                // Firefox bug fix
                input.addEventListener('focus', function () { input.classList.add('has-focus'); });
                input.addEventListener('blur', function () { input.classList.remove('has-focus'); });
            });
        }(document, window, 0));
    /*upload resume file input name showing function*/
    return (
        <>
            <form action="#" className="zForm white">
                <div className="inpGroup">
                    <label htmlFor="fname">Firstname*</label>
                    <input type="text" name="firstname" id='firstname' placeholder='John' value={firstName} required onChange={e => setFirstName(e.target.value)} />
                </div>
                <div className="text-danger">{errors.firstname}</div>
                <div className="inpGroup">
                    <label htmlFor="lname">Lastname*</label>
                    <input type="text" name="lastname" id='lname' placeholder='Doe' required value={lastName} onChange={e => setLastName(e.target.value)} />
                </div>
                <div className="text-danger">{errors.lastname}</div>

                <div className="inpGroup">
                    <label htmlFor="phone">Phone</label>
                    {/* 
                    <div className="phoneNumber">
                        <select name="countryCode" id="countryCode" onChange={e => setPhoneCountryCode(e.target.value)}>
                            <option value="+91">+91</option>
                            <option value="+49">+49</option>
                            <option value="+31">+31</option>
                            <option value="+33">+33</option>
                        </select>
                        <input type="tel" name="phone" id='phone' value={phone} placeholder='Phone' onChange={e => setPhone(e.target.value)} />
                    </div>
                    */}
                    <PhoneInput country={'in'} placeholder="+91 XXXXX-XXXXX" name="work_phone" onChange={handleOnChangework} />
                    <div className="text-danger">{errors.phone}</div>
                </div>

                <div className="inpGroup">
                    <label htmlFor="email">Email*</label>
                    <input type="text" name="email" id='email' placeholder='johndoe@email.com' value={email} required onChange={e => setEmail(e.target.value)} />
                </div>
                <div className="text-danger">{errors.email}</div>
                <div className="inpGroup">
                    <label htmlFor="pswd">Password*</label>
                    <input type="password" name="pswd" id='pswd' placeholder='Your Password' required onChange={e => setPassword(e.target.value)} />
                </div>
                <div className="text-danger">{errors.password}</div>
                <div className="inpGroup">
                    <label htmlFor="cpswd">Confirm Password*</label>
                    <input type="password" name="password_confirmation" id='cpswd' placeholder='Your Password Again' required onChange={e => setConfirmPassword(e.target.value)} />
                </div>
                <div className="text-danger">{errors.password_confirmation}</div>
                <div className="inpGroup">
                    <label htmlFor="fileResume">Upload Resume (optional)</label>
                    <div class="fileInpWrapper">
                        <input type="file" name="fileResume" id="fileResume" accept=".pdf,.docx" key={resumeKey} class="fileinp inputfile inputfile-1" data-multiple-caption="{count} files selected" onChange={e => setResume(e.target.files[0])} />
                        <label class="file-inp-l" for="fileResume"><span>&nbsp; Choose File</span><i className="bi bi-cloud-arrow-up"></i></label>
                    </div>
                </div>
                <div className="text-danger">{errors.resume}</div>
                <div className="bottomSection" >
                    &nbsp;
                    <button type="button" className='mainBtn' onClick={addResume} disabled={savingResume}>
                        {savingResume &&
                            <div className="spinner-grow spinner-grow-sm mr-5" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        }Submit</button>
                </div>
                {/* <div>
                    <div className="row">
                        <div className="col-sm-6">
                            <GoogleLogin
                                clientId={process.env.REACT_APP_GOOGLE_SECRET_ID}
                                buttonText="Login with Google"
                                onSuccess={successResponseGoogle}
                                onFailure={errorResponseGoogle}
                                cookiePolicy={'single_host_origin'}
                                className='socialBtn'
                            />
                        </div>
                        <div className="col-sm-6">
                            <LinkedInPage />
                        </div>
                    </div>
                </div> */}
                {/* <div className="row">
                    <div className="col-sm-6"><button className="socialBtn"><img src="/img/google_icon.png" alt="Google" className="img-fluid" /><span>Signup with Google</span></button></div>
                    <div className="col-sm-6"><button className="socialBtn"><img src="/img/linkedin_icon.png" alt="LinkedIn" className="img-fluid" /><span>Signup with LinkedIn</span></button></div>
                </div> */}
            </form>
        </>
    )
}