import React from 'react';
import { Box, Grid, Typography, Button, Container } from '@mui/material';
import { useNavigate } from "react-router-dom";

const Section7 = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        backgroundColor: '#1a1a1a',
        color: 'white',
        py: 8,
      }}
    >
      <Container
        sx={{
          px: { xs: 2, sm: 4 },
          minWidth:'95%',  // Responsive horizontal padding
        }}
      >
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{ 
            fontWeight: 'medium', 
            mb: 6, 
            color: '#FFD700',
            fontSize: { xs: "24px", md: "32px" }  // Responsive font size
          }}
        >
          PRICING
        </Typography>

        <Grid container spacing={4} justifyContent="center">
          {/* Basic Plan */}
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                backgroundColor: '#000',
                borderRadius: 2,
                p: { xs: 2, sm: 4 },  // Responsive padding inside cards
                boxShadow: 3,
                textAlign: 'left',
                height: { xs: "100%", md: "100%" ,},
                width: { xs: "100%", sm: "100%" },
                maxWidth:'400px',
                mx:'auto'
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 'semibold', color: '#FFD700', fontFamily: "Montserrat" ,fontSize:'18px'}}>
                Basic - €799/mo
              </Typography>
              <Typography component="ul" sx={{ mt: 2, fontFamily: "Montserrat", fontSize: { xs: "14px", md: "16px" } }}>
                <li>Basic Kubernetes Infrastructure using AWS/GCP/Azure.</li>
                <li>Monitoring & Alerting</li>
                <li>Regular Upgrades</li>
              </Typography>
            </Box>
          </Grid>

          {/* Advanced Plan */}
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                backgroundColor: '#000',
                borderRadius: 2,
                p: { xs: 2, sm: 4 },
                boxShadow: 3,
                textAlign: 'left',
                height: { xs: "100%", md: "100%" ,},
                width: { xs: "100%", sm: "100%" },
                maxWidth:'400px',
                mx:'auto'
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 'semibold', color: '#FFD700', fontFamily: "Montserrat",fontSize:'18px' }}>
                Advanced - €999/mo
              </Typography>
              <Typography component="ul" sx={{ mt: 2, fontFamily: "Montserrat", fontSize: { xs: "14px", md: "16px" } }}>
                <li>Kubernetes infrastructure with separate environments for Development, Production, and Deployment.</li>
                <li>Basic CI-CD Setup</li>
                <li>Regular Upgrades</li>
                <li>Up to 5 hrs of Support</li>
              </Typography>
            </Box>
          </Grid>

          {/* Professional Plan */}
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                backgroundColor: '#000',
                borderRadius: 2,
                p: { xs: 2, sm: 4 },
                boxShadow: 3,
                textAlign: 'left',
                height: { xs: "100%", md: "100%" ,},
                width: { xs: "100%", sm: "100%" },
                maxWidth:'400px',
                mx:'auto'
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 'semibold', color: '#FFD700', fontFamily: "Montserrat" ,fontSize:'18px'}}>
                Professional - €1199/mo
              </Typography>
              <Typography component="ul" sx={{ mt: 2, fontFamily: "Montserrat", fontSize: { xs: "14px", md: "16px" } }}>
                <li>Kubernetes Infrastructure with 3 clusters</li>
                <li>Advanced CI-CD Setup with Security</li>
                <li>Up to 16 hrs Support</li>
                <li>Tightened Security</li>
              </Typography>
            </Box>
          </Grid>

          {/* Custom Plan */}
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                backgroundColor: '#000',
                borderRadius: 2,
                p: { xs: 2, sm: 4 },
                boxShadow: 3,
                textAlign: 'left',
                height: { xs: "100%", md: "100%" ,},
                width: { xs: "100%", sm: "100%" },
                maxWidth:'400px',
                //minHeight:'350px',
                mx:'auto'
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 'semibold', color: '#FFD700', fontFamily: "Montserrat" ,fontSize:'18px'}}>
                Custom
              </Typography>
              <Typography sx={{ mt: 2, mb: 4, fontFamily: "Montserrat", fontSize: { xs: "14px", md: "16px" } }}>
                Contact Sales for custom plan
              </Typography>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#FFD700',
                  color: '#000',
                  fontWeight: 'bold',
                  borderRadius: 2,
                  borderColor: "#FFFFFF",
                  px: 4,
                  mt: { xs: 4, md: 8 },  // Adjusted margin for button
                  mx: "auto",
                  display: "block",
                  "&:hover": {
                    backgroundColor: "#FFFFFF",
                    color: "#000",
                    transform: "scale(1.1)",
                    transition: "all 0.2s ease-out",
                  },
                }}
                onClick={() => navigate("/form")}
              >
                Contact Now
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Section7;
