import React from "react";
import { Typography, Box } from "@mui/material";

const Section2 = () => {
    return (
        <Box
            sx={{
                backgroundColor: "#1A1A1A",
                backgroundSize: "cover",
                backgroundPosition: "center",
                width: "100%",
                height: { xs: "auto", md: "232px" },  // Responsive height
                display: "flex",
                flexDirection: "column",  // Stack elements vertically
                alignItems: "center",
                justifyContent: "center",
                padding: { xs: "20px", md: "0" },  // Add padding for mobile
                textAlign: "center"
            }}
        >
            <Typography
                sx={{
                    color: "#FAD32E",
                    fontWeight: 500,
                    fontFamily: "Montserrat",
                    fontSize: { xs: "18px", sm: "20px" },  // Responsive font size
                    marginBottom: { xs: "10px", md: "20px" }  // Spacing between texts
                }}
            >
                Why Choose Hashinfra?
            </Typography>
            <Typography
                sx={{
                    color: "#FFFFFF",
                    fontWeight: 400,
                    fontFamily: "Montserrat",
                    fontSize: { xs: "14px", sm: "16px", md: "18px" },  // Responsive font size
                    width: { xs: "90%", sm: "80%", md: "70%" }  // Adjust width based on screen size
                }}
            >
                Hashinfra is designed to simplify and optimize infrastructure management, offering robust scalability, high availability, and seamless microservices orchestration. By leveraging cost-effective open-source platforms, it provides automated workflows, real-time observability, and enhanced security, empowering businesses to focus on innovation without worrying about operational complexities.
            </Typography>
        </Box>
    );
};

export default Section2;
