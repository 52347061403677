import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import one from "../Images/one.png";
import two from "../Images/two.png";
import three from "../Images/three.png";
import four from "../Images/four.png";

const BenefitsSection = () => {
  const benefits = [
    {
      src: one, // Replace with an appropriate icon or image
      title: "Simplify Microservice Management",
      description: "Cut down on the hassle of handling connected services.",
    },
    {
      src: two, // Replace with an appropriate icon or image
      title: "Enhance Operational Performance",
      description: "Keep things up and running with auto scaling and backup plans.",
    },
    {
      src: three, // Replace with an appropriate icon or image
      title: "Gain Real-Time Insights",
      description: "Keep an eye on your setup and services with built-in tracking tools.",
    },
    {
      src: four, // Replace with an appropriate icon or image
      title: "Prepare for Growth",
      description: "Scale up your resources without a hitch as your workload gets bigger.",
    },
  ];

  return (
    <Box sx={{ 
        bgcolor: "#1A1A1A", 
        color: "white", 
        py: { xs: 4, sm: 8 }, // Adjust padding for different screen sizes
        minHeight: { xs: "auto", sm: "600px" }, // Adjust height for different screen sizes
      }}>

      <Typography
        variant="h4"
        align="center"
        sx={{ 
          color: "gold",
          mb: 4,
          textTransform: "uppercase",
          fontFamily: "Montserrat",
          fontSize: { xs: "20px", sm: "25px" }, // Adjust font size for different screen sizes
          fontWeight: "medium",
        }}
      >
        Benefits
      </Typography>
      <Typography 
        variant="body1" 
        align="center" 
        sx={{ 
          mb: { xs: 4, sm: 6, md: 10 }, // Adjust margin bottom for different screen sizes
          width: { xs: "90%", sm: "80%" }, // Adjust width for different screen sizes
          mx: "auto", // Center the text
          fontFamily: "Montserrat",
          fontSize: { xs: "14px", sm: "16px" }, // Adjust font size for different screen sizes
          fontWeight: "medium",
        }}
      >
        Hashinfra makes microservices management easier enabling businesses adopt
        modern DevOps methods and reach their full potential.
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {benefits.map((benefit, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            key={index}
            sx={{ 
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              px: { xs: 2, sm: 4 }, // Adjust padding for different screen sizes
            }}
          >
           <Box 
              component="img"
              src={benefit.src}
              alt={benefit.title}
              sx={{ 
                width: { xs: "50px", sm: "70px" }, // Adjust image size for different screen sizes
                height: { xs: "50px", sm: "70px" },
                mb: { xs: 2, sm: 3 }, // Adjust margin bottom for different screen sizes
                objectFit: "contain",
              }}
            />
            <Typography 
              variant="h6" 
              sx={{ 
                color: "#FAD32E", 
                maxWidth: "190px",
                fontFamily: "Montserrat",
                fontWeight: "semibold",
                textAlign: "center", // Center text on smaller screens
                mb: { xs: 1, sm: 2 }, // Adjust margin bottom for different screen sizes
                fontSize: { xs: "16px", sm: "18px" }, // Adjust font size for different screen sizes
              }}
            >
              {benefit.title}
            </Typography>
            <Typography 
              variant="body2"
              sx={{
                color: "#FFFFFF",
                fontFamily: "Montserrat",
                maxWidth: "190px",
                textAlign: "center", // Center text on smaller screens
                fontSize: { xs: "12px", sm: "14px" }, // Adjust font size for different screen sizes
              }}
            >
              {benefit.description}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default BenefitsSection;