import React from 'react';
import { Grid, Box, Typography, Container } from '@mui/material';
import bgimg3 from '../Images/bgimg3.png';
import bg61 from '../Images/bg61.png';
import bg62 from '../Images/bg62.png';
import bg63 from '../Images/bg63.png';

const Section6 = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${bgimg3})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: { xs: 'auto', md: '750px' }, // Adjust height for smaller screens
        color: 'white',
        py: { xs: 4, md: 8 }, // Adjust padding for smaller screens
        position: 'relative',
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{
            color: '#FAD32E',
            fontWeight: 'medium',
            mb: { xs: 4, md: 6 }, // Adjust margin for smaller screens
            fontFamily: 'Montserrat',
            fontSize: { xs: '20px', md: '25px' }, // Adjust font size for smaller screens
          }}
        >
          WHO CAN BENEFIT FROM HASHINFRA?
        </Typography>
        <Grid
          container
          spacing={4}
          sx={{
            position: { xs: 'static', md: 'absolute' }, // Adjust positioning for smaller screens
            top: { xs: 'auto', md: '141px' },
            left: { xs: 'auto', md: '50%' },
            transform: { xs: 'none', md: 'translateX(-50%)' },
            width: { xs: '100%', md: '90%' },
          }}
        >
          {/* Startups */}
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                position: 'relative',
                height: { xs: '300px', md: '503px' }, // Adjust height for smaller screens
                backgroundImage: `url(${bg61})`,
                backgroundSize: 'cover',
                borderRadius: '12px',
                overflow: 'hidden',
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: '#00000080',
                }}
              />
              <Typography
                sx={{
                  position: 'absolute',
                  bottom: { xs: '70px', md: '91px' }, // Adjust positioning for smaller screens
                  left: '20px',
                  fontSize: { xs: '20px', md: '24px' }, // Adjust font size for smaller screens
                  fontWeight: 600,
                  background:
                    'linear-gradient(180deg, rgb(250, 211, 46) 0%, rgba(250, 211, 46, 0.4) 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  zIndex: 1,
                }}
              >
                Startups
              </Typography>
              <Typography
                sx={{
                  position: 'absolute',
                  bottom: { xs: '20px', md: '30px' }, // Adjust positioning for smaller screens
                  left: '20px',
                  color: '#fff',
                  fontSize: { xs: '16px', md: '18px' }, // Adjust font size for smaller screens
                  fontWeight: 500,
                  lineHeight: '1.5',
                }}
              >
                Leverage our development expertise to develop custom solutions.
              </Typography>
            </Box>
          </Grid>

          {/* Enterprises */}
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                position: 'relative',
                height: { xs: '300px', md: '503px' }, // Adjust height for smaller screens
                backgroundImage: `url(${bg62})`,
                backgroundSize: 'cover',
                borderRadius: '12px',
                overflow: 'hidden',
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: '#00000080',
                }}
              />
              <Typography
                sx={{
                  position: 'absolute',
                  bottom: { xs: '70px', md: '91px' }, // Adjust positioning for smaller screens
                  left: '20px',
                  fontSize: { xs: '20px', md: '24px' }, // Adjust font size for smaller screens
                  fontWeight: 600,
                  background:
                    'linear-gradient(180deg, rgb(250, 211, 46) 0%, rgba(250, 211, 46, 0.4) 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
              >
                Enterprises
              </Typography>
              <Typography
                sx={{
                  position: 'absolute',
                  bottom: { xs: '20px', md: '30px' }, // Adjust positioning for smaller screens
                  left: '20px',
                  color: '#fff',
                  fontSize: { xs: '16px', md: '18px' }, // Adjust font size for smaller screens
                  fontWeight: 500,
                  lineHeight: '1.5',
                }}
              >
                Simplify microservice operations and improve scalability.
              </Typography>
            </Box>
          </Grid>

          {/* DevOps Teams */}
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                position: 'relative',
                height: { xs: '300px', md: '503px' }, // Adjust height for smaller screens
                backgroundImage: `url(${bg63})`,
                backgroundSize: 'cover',
                borderRadius: '12px',
                overflow: 'hidden',
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: '#00000080',
                }}
              />
              <Typography
                sx={{
                  position: 'absolute',
                  bottom: { xs: '70px', md: '91px' }, // Adjust positioning for smaller screens
                  left: '20px',
                  fontSize: { xs: '20px', md: '24px' }, // Adjust font size for smaller screens
                  fontWeight: 600,
                  background:
                    'linear-gradient(180deg, rgb(250, 211, 46) 0%, rgba(250, 211, 46, 0.4) 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
              >
                DevOps Teams
              </Typography>
              <Typography
                sx={{
                  position: 'absolute',
                  bottom: { xs: '20px', md: '30px' }, // Adjust positioning for smaller screens
                  left: '20px',
                  color: '#fff',
                  fontSize: { xs: '16px', md: '18px' }, // Adjust font size for smaller screens
                  fontWeight: 500,
                  lineHeight: '1.5',
                }}
              >
                Automate workflows and optimize microservice management.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Section6;