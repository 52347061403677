import React, { useState, useEffect } from 'react';
import { TextField, Box, Grid, Typography ,Button} from "@mui/material";
import http from '../../httpService';

const formFields = [
    { id: "fullName", label: "Full Name" },
    { id: "companyName", label: "Company Name" },
    { id: "emailAddress", label: "Email Address" },
];

const Popupz = (props) => {
  return (
      <div className={"bgOverlay " + props.className} id={props.id}>
          <div className="popupCard">
              <button className="closeBtn" onClick={() => props.closePopup()}>
                  <i className="bi bi-x-lg"></i>
              </button>
              <h2 className="popupHead">{props.title}</h2>
              <div className="maxHeight">{props.children}</div>
          </div>
      </div>
  );
};

const Form = () => {
    const [formData, setFormData] = useState({
        fullName: '',
        companyName: '',
        emailAddress: '',
    });
    useEffect(() => {
      window.scrollTo(0, 0); // Scrolls to top when the component is loaded
  }, []);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [showPopup, setShowPopup] = useState('');
    const [hasBeenHovered, setHasBeenHovered] = useState(false); // For hover effects
    const [errors, setErrors] = useState({
        fullName: '',
        companyName: '',
        emailAddress: '',
    });

    // Automatically set hasBeenHovered to true after the component mounts
    useEffect(() => {
        setHasBeenHovered(true);
    }, []);

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {
            fullName: "",
            companyName: "",
            emailAddress: "",
        };

        if (!formData.fullName.trim()) {
            newErrors.fullName = "Please enter your full name.";
            formIsValid = false;
        }
        if (!formData.companyName.trim()) {
            newErrors.companyName = "Please enter your company name.";
            formIsValid = false;
        }
        if (!/\S+@\S+\.\S+/.test(formData.emailAddress)) {
            newErrors.emailAddress = "Please enter a valid email address.";
            formIsValid = false;
        }

        setErrors(newErrors);
        return formIsValid;
    };

    const Save = async (event) => {
        event.preventDefault();
        if (!validateForm()) return;

        // Construct the URL directly with query parameters
        const url = `api/join-hashstack?name=${formData.fullName}&email=${formData.emailAddress}&companyname=${formData.companyName}`;

        // Log the data being sent
        console.log("Form Data Being Sent:", formData);
        console.log("Request URL:", url);

        try {
            // Make the GET request to the constructed URL
            let response = await http.post(url);

            // Log the response from the API
            console.log("API Response:", response);

            if (response.data) {
                setMessage("Successfully Subscribed!");
                setFormData({ fullName: '', companyName: '', emailAddress: '' });
                setError("");
                setShowPopup("active");

                // Log success message
                console.log("Form submitted successfully!");
            }
        } catch (exception) {
            // Log the error
            console.error("API Error:", exception);

            if (exception.response && exception.response.data) {
                setError(exception.response.data);

                // Log the error response from the API
                console.log("Error Response Data:", exception.response.data);
            } else {
                // Log a generic error message if no response is available
                console.log("An unexpected error occurred:", exception.message);
            }
        }
    };

    return (
        <Box sx={{ minHeight: "100vh", display: "flex", alignItems: "center", justifyContent: "center", bgcolor: "black" }}>
            <Box sx={{ width: "100%", maxWidth: 1400, bgcolor: "transparent", border: "none"}}>
                <Grid container spacing={6} sx={{ bgcolor: "transparent", p: 4, justifyContent: "space-between", alignItems: "center" }}>
                    {/* Left Section */}
                    <Grid item xs={12} lg={6}>
                        <Box sx={{ textAlign: { xs: "center", lg: "left" }, mb: 1 }}>
                            <Typography variant="h6" sx={{ color: "white", fontWeight: 500, letterSpacing: 1 ,fontFamily:"Montserrat",}}>
                                See Hashinfra In Action
                            </Typography>
                            <Typography variant="h3" sx={{ fontWeight: 500, mt: 2, lineHeight: 1.2 ,fontFamily:"Montserrat"}}>
                                <span style={{ color: "white" ,fontFamily:"Montserrat"}}>Stay ahead with the next breakthrough in </span>
                                <span style={{ color: "#FFD700",fontFamily:"Montserrat" }}>DevOps.
                                </span>
                            </Typography>
                            
                        </Box>
                    </Grid>

                    {/* Right Section */}
                    <Grid item xs={12} lg={6}>
  <Box
    sx={{
      maxWidth: 530,
      display: "flex",
      flexDirection: "column",
      gap: 6, // Ensures equal spacing between fields & button
      alignItems: "center",
    }}
  >
    {formFields.map((field) => (
      <Grid
        key={field.id}
        item
        sx={{
          position: "relative",
          width: "100%",
          opacity: hasBeenHovered ? 1 : 0,
          transform: hasBeenHovered ? "translateY(0)" : "translateY(20px)",
          transition: `all 0.6s ease-out ${1.4 + formFields.indexOf(field) * 0.2}s`,
          fontFamily: "Raleway",
        }}
      >
        <TextField
          fullWidth
          variant="standard"
          id={field.id}
          label={field.label}
          value={formData[field.id]}
          onChange={handleChange}
          error={!!errors[field.id]}
          helperText={errors[field.id]}
          sx={{
            "& .MuiInputBase-input": {
              color: "white",
              WebkitTextFillColor: "white",
              opacity: 1,
            },
            "& .MuiInputLabel-standard": {
              color: "#7A7A7A",
              transition: "color 0.3s ease",
            },
            "& .MuiInput-underline:before": {
              borderBottomColor: "#7A7A7A",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "#B67652",
            },
          }}
        />
      </Grid>
    ))}

<Button
  variant="contained"
  sx={{
    textTransform:'none',
    padding:"10px 25px",
    fontFamily:"inherit",
    fontSize:"inherit",
    backgroundColor: "#FAD32E",
    color: "#000",
    fontWeight: "regular",
    borderRadius: "10px",
    borderColor: "#FFFFFF",
    px: 4,
    mt: 2,
    mx: "auto",
    display: "block",
    opacity: hasBeenHovered ? 1 : 0,
    transform: hasBeenHovered ? "translateY(0)" : "translateY(20px)",
    transition: `all 0.6s ease-out ${1.4 + formFields.length * 0.2}s`, // Delay after the last field
    "&:hover": {
      // backgroundColor: "#FFFFFF",
      // color: "#000",
      // transform: "scale(1.1)",
      // transition: "all 0.1s ease-out 0.1s",
      backgroundColor: "#82712e",  // Correct hover background color
      color: "#FFFFFF",            // Ensure text color changes
      transform: "scale(1.05)",    // Slight scaling effect
      transition: "all 0.3s ease-in-out",
    },
  }}
  onClick={Save} 
>
  Submit
</Button>

  </Box>
</Grid>

                </Grid>
            </Box>
            <Popupz title="Success" id="coverSuccess" className={showPopup} closePopup={() => setShowPopup("")}>
                <p>{message}</p>
                <button className="mainBtn" onClick={() => setShowPopup("")}>
                    Close
                </button>
            </Popupz>
        </Box>
    );
};

export default Form;