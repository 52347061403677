import React from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import bgimage from "../Images/bgimage.png";
import icon from "../Images/hashinfraicon.svg";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const HashinfraLandingPage = () => {
  const [currentText, setCurrentText] = useState("Transform with Hashinfra.");
  const texts = [
    "Transform with Hashinfra.",
    "Build with Hashinfra.",
    "Scale with Hashinfra.",
  ];
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      const currentIndex = texts.indexOf(currentText);
      const nextIndex = (currentIndex + 1) % texts.length;
      setCurrentText(texts[nextIndex]);
    }, 2000);

    return () => clearInterval(interval);
  }, [currentText]);

  return (
    <Box
      sx={{
        backgroundImage: `url(${bgimage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        zIndex: 0,
      }}
    >
      {/* Overlay */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1,
        }}
      />

      {/* Content */}
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        sx={{ color: "#fff", textAlign: "center", padding: { xs: 2, sm: 3 }, zIndex: 2 }}
      >
        {/* Right Content - Icon First on Mobile */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            order: { xs: 1, md: 2 },  // Icon first on mobile, second on desktop
          }}
        >
          <Box>
            <img
              src={icon}
              alt="HashInfra Logo"
              style={{
                width: "100%",
                maxWidth: "510px",
                height: "auto",
                marginTop: { xs: "20px", md: "0" },
              }}
            />
          </Box>
        </Grid>

        {/* Left Content - Text Second on Mobile */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            order: { xs: 2, md: 1 },  // Text second on mobile, first on desktop
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontFamily: "Montserrat",
              textAlign: { xs: "center", md: "left" },
              fontSize: { xs: "24px", sm: "28px", md: "34px" },
              fontWeight: "bold",
              ml: { xs: 0, md: "80px" },
            }}
          >
            <span style={{ color: "#FAD32E" }}>{currentText.split(" ")[0]}</span>{" "}
            {currentText.split(" ").slice(1).join(" ")}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontFamily: "Montserrat",
              textAlign: { xs: "center", md: "left" },
              mt: 2,
              fontSize: { xs: "14px", sm: "16px", md: "18px" },
              width: { xs: "100%", md: "500px" },
              fontWeight: "bold",
              ml: { xs: 0, md: "80px" },
              px: { xs: 2, md: 0 }, // Add padding on small screens
            }}
          >
            The all-in-one cloud-native platform for managing modern micro
            services. Simplify infrastructure, automate workflows, and scale
            seamlessly to meet your business needs.
          </Typography>
          <Box
            sx={{
              mt: 4,
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: { xs: 2, sm: 0 },
              justifyContent: { xs: "center", md: "flex-start" },
              ml: { xs: 0, md: "80px" },
            }}
          >
            <Button
              variant="contained"
              sx={{
                fontWeight: "bold",
                px: 4,
                py: 1,
                borderRadius: "8px",
                fontFamily: "Montserrat",
                backgroundColor: "#FAD32E",
                color: "#000",
                "&:hover": {
                  backgroundColor: "#000000",
                  color: "#FAD32E",
                  transform: "scale(1.1)",
                  transition: "all 0.1s ease-out 0.1s",
                },
              }}
              onClick={() => navigate("/form")}
            >
              Get Started
            </Button>
            <Button
              variant="outlined"
              sx={{
                fontWeight: "bold",
                px: 4,
                py: 1,
                borderRadius: "8px",
                fontFamily: "Montserrat",
                color: "#FAD32E",
                borderColor: "#FAD32E",
                ml: { xs: 0, sm: 2 },
                mt: { xs: 2, sm: 0 },
                "&:hover": {
                  backgroundColor: "#000000",
                  color: "#FAD32E",
                  borderColor: "#000000",
                  transform: "scale(1.1)",
                  transition: "all 0.1s ease-out 0.1s",
                },
              }}
              onClick={() =>
                (window.location.href =
                  "https://calendly.com/girish-g-clockhash/hashinfra")
              }
            >
              Request Demo
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HashinfraLandingPage;
