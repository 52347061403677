import Landing from "./Autocicd/Landing";
import Page2 from "./Autocicd/Page2";   
import React from "react";

const Autocicd = () => {
    return (
      <div>
        
        <Landing />
        <Page2 />
       
        
      </div>
    )
  }
  
  export default Autocicd
