import { compose } from "@mui/system";
import React, { Component } from "react";
import LangList from "./LangList";
import LoginLogoutButton from "./LoginLogoutButton";
import http from "../httpService";
import { useNavigate } from "react-router-dom";


class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: undefined,
            email: undefined,
            phone: undefined,
            message: undefined,
            savingContactUs: false,
            hireformus: undefined
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.tabScroll);
        //this.UserPagePermission();
        this.pagepermission();
    }

    HireForm() {
        return (
            <a className="navLink" href="/hire">Hire From Us</a>
        )
    }

    checkAge(age) {
        return age == window.location.pathname;
    }

    pagepermission = async () => {
        let authData = localStorage.getItem('AuthData');
        if (authData) {
            try {
                const response = await http.get('api/userrole');
                if (response.data.is_hirer) {
                    this.setState({
                        hireformus: this.HireForm()
                    })
                }
            } catch (exception) {

            }
        }
    }
    /*
    UserPagePermission = async () => {

        try {
            let locationpath = window.location.pathname;
            let authData = localStorage.getItem('AuthData');
            if (authData) {
                const formData = new FormData();
                formData.append('slug', locationpath);
                let response = await http.post("api/pagepermission", formData);
                if (response.data.permission === false) {
                    window.location.href = response.data.redirecturl;
                } else {
                    /*
                    if (response.data.user && response.data.is_hirer) {
                        this.setState({
                            hireformus: this.HireForm()
                        })
                    }
                }
            }
        } catch (exception) {

        }
    }
    */

    handleChange = (e) => {

        const target = e.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        this.setState({ [name]: value });
    };

    // ContactForm = async () => {

    //     this.setState({
    //         savingContactUs: true,
    //     });
    //     var data = {
    //         name : this.state.name,
    //         email : this.state.email,
    //         phone : this.state.phone,
    //         message : this.state.message,
    //     }

    //     await http.post('api/contact-form/mail',data);
    //     this.setState({
    //         savingContactUs: false,
    //     });
    //     this.hideContactForm();
    // }

    navToggle = () => {
        var nvList = document.getElementById("navComp");
        if (nvList.style.display === "none")
            nvList.style.display = "block"
        else
            nvList.style.display = "none";
    }

    // window.onscroll = tabScroll;

    tabScroll() {
        const mainNav = document.getElementById('mainNav');

        if (mainNav) {
            if (window.pageYOffset > 200) {
                mainNav.classList.add("scrolled");
            }
            else {
                mainNav.classList.remove("scrolled");
            }
        }
    };

    scrollSpy = () => {
        var i = 0;
        const currentURL = window.location.href;
        const spy = document.querySelectorAll(".navLink");
        for (i = 0; i < spy.length; i++) {
            if (spy[i].href == currentURL) {
                spy[i].classList.add("active");
            }
            else {
                var t = currentURL;
                t = t.substr(0, t.lastIndexOf("/"));
                if (spy[i].href == t) {
                    spy[i].classList.add("active");
                }
            }
        }
    }

    render() {
        return (
            <>
                <header className="clkHead" id="mainNav" onLoad={this.scrollSpy}>
                    <nav className="clkNav">
                        <a className="navLogo" href="/"><img src="/img/logo.svg" alt="logo" /></a>
                        <button className="navToggler" onClick={this.navToggle}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                        <div className="navComp" id="navComp">
                            <ul className="navList">
                                {/* <li className="navLi"><a className="navLink" href="/">Hire from us</a></li> */}
                                <li className="navLi"><a className="navLink" href="/about-us">About Us</a></li>
                                <li className="navLi"><a className="navLink" href="/why-us">Why us</a></li>
                                <li style={{ position: 'relative' }} onMouseEnter={(e) => e.currentTarget.querySelector('.dropdown-menu').style.display = 'block'} onMouseLeave={(e) => e.currentTarget.querySelector('.dropdown-menu').style.display = 'none'}>
                                <a className="navLink" href="#" style={{ padding: '10px', cursor: 'pointer' }}>Products</a>
                                <ul className="dropdown-menu" style={{ display: 'none', position: 'absolute', top: '100%', left: '0', backgroundColor: 'rgba(0,0,0,0.7)',backdropFilter: 'blur(10px)',boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', padding: '10px 0', minWidth: '150px', zIndex: 1000 }}>
                                    <li><a href="/hashinfra" style={{ display: 'block', padding: '8px 16px' }}>HashInfra</a></li>
                                    <li><a href="/autocicd" style={{ display: 'block', padding: '8px 16px' }}>Auto CI-CD</a></li>
                                    <li><a href="/cloudash" style={{ display: 'block', padding: '8px 16px' }}>Clouddash</a></li>
                                    <li><a href="/contact" style={{ display: 'block', padding: '8px 16px' }}>IamKube</a></li>
                                </ul>
                            </li>
                                <li className="navLi"><a className="navLink" href="/contact" onClick={this.showContactForm}>Contact</a></li>
                                <li className="navLi"><a className="navLink" href="/careers">Careers</a></li>
                                <li className="navLi">{this.state.hireformus}</li>
                            </ul>
                            <ul className="rightlist">
                                {/* <li><a className="altBtn" href="/login">Login/SignUp</a></li> */}
                                <li><LoginLogoutButton /></li>
                                {/* <li><a href="/" className="mainBtn">Employer Login</a></li> */}
                                {/* <li><a href="https://www.calendar.com/clockhash-technologies/" target="_blank" className="altBtn">Book Appointment</a></li> */}
                                <li>
                                    <LangList />
                                </li>
                                <li>
                                    <a href="/your_trusted_cloud_solutions_partner" className="mainBtn">Consult with us</a>
                                </li>
                                {/* <li>
                                    <Logout />
                                </li> */}
                            </ul>
                        </div>
                    </nav>
                </header>
            </>
        )
    }
};

export default Header;
