import React from "react";
import { Box, Typography } from "@mui/material";
import test from "../Images/test.png";
import testbg from "../Images/testbg.png";

const Section8 = () => {
  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        backgroundImage: `url(${testbg})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: "#ffffff",
        backgroundColor: "#1A1A1A",
        minHeight: { xs: "auto", md: "533px" },  // Responsive height
        py: { xs: 4, md: 0 },  // Padding for smaller screens
      }}
    >
      {/* Testimonial Title */}
      <Typography
        sx={{
          fontSize: { xs: "20px", md: "25px" },  // Responsive font size
          fontWeight: 700,
          textAlign: "center",
          color: "#FAD32E",
          mb: 3,
        }}
      >
        TESTIMONIALS
      </Typography>

      {/* Image */}
      <Box
        component="img"
        src={test}
        alt="Download"
        sx={{
          height: { xs: "120px", md: "176px" },  // Responsive image size
          width: { xs: "110px", md: "160px" },
          objectFit: "cover",
          mb: 3,
        }}
      />

      {/* Testimonial Text */}
      <Typography
        sx={{
          fontSize: { xs: "16px", md: "18px" },  // Responsive font size
          fontWeight: 400,
          textAlign: "center",
          lineHeight: "28px",
          width: { xs: "90%", md: "884px" },
          px: 2,
        }}
      >
        Hashinfra’s cloud-native DevOps platform has revolutionized our
        infrastructure management. With AWS, Kubernetes, and tools like ArgoCD,
        Prometheus, and Grafana, we’ve achieved scalability, security, and
        efficiency. The platform's high availability and disaster recovery
        features allow us to focus on innovation without the complexity of
        infrastructure. A game-changer for our team!
      </Typography>
    </Box>
  );
};

export default Section8;
