import React, { useEffect } from "react";
import PartnerSlider from "../../components/PartnerSlider";
import Testimonials from "../../components/Testimonials";
import { Helmet } from "react-helmet";
const Landing_new = () => {
  useEffect(() => {
    const noscriptElement = document.createElement("noscript");
    const iframeElement = document.createElement("iframe");
    iframeElement.src =
      "https://www.googletagmanager.com/ns.html?id=GTM-WHXWXC9Z";
    iframeElement.height = "0";
    iframeElement.width = "0";
    iframeElement.style.display = "none";
    iframeElement.style.visibility = "hidden";
    noscriptElement.appendChild(iframeElement);
    document.body.firstElementChild.insertAdjacentElement(
      "afterend",
      noscriptElement
    );
  }, []);
  return (
    <>
      <main>
        <Helmet>
          <script>
            {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-WHXWXC9Z');
            `}
          </script>
        </Helmet>
        <section className="landingBanner" id="heroSection">
          <video autoPlay muted loop>
            <source src="./video/banner.mp4" type="video/mp4" />
            <source src="./video/banner.webm" type="video/webm" />
            <source src="./video/banner.ogv" type="video/ogv" />
          </video>
          <div className="bannerText">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <h1>
                    ClockHash Technologies: Your Trusted Partner for
                    Comprehensive IT Solutions
                  </h1>
                  <p>
                    At ClockHash Technologies, we're your trusted partner for
                    comprehensive IT solutions. Specializing in 'Team
                    Augmentation' and 'Offshore Development' we leverage the
                    latest technologies, including Cloud, DevOps, Full Stack,
                    AI/ML, and IoT, to help you achieve your goals.
                  </p>
                  <a
                    href="/your_trusted_cloud_solutions_partner"
                    className="mainBtn"
                  >
                    Get in Touch with Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="landingAbout" className="sectionPad">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-lg-6 picside">
                <img
                  src="https://images.pexels.com/photos/7988087/pexels-photo-7988087.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                  alt="coding"
                />
              </div>
              <div className="col-lg-5">
                <div className="mainTitle dark left">
                  <h2>Unlock Your IT Potential with ClockHash</h2>
                </div>
                <p className="mb-0">
                  Maximise Your IT Potential with ClockHash Technologies. We
                  offer comprehensive services, including cloud computing,
                  managed IT, network design, security, and automation solutions
                  via flexible engagement models.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="second">
          <section id="topTalents" className="sectionPad">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="mainTitle">
                    <h2>What do We Master</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 d-grid">
                  <div className="glassCard">
                    <img
                      src="/img/services/cloud.svg"
                      alt="cloud_administration"
                    />
                    <h4>Cloud Administration</h4>
                    <ul>
                      <li>AWS, Azure, GCP</li>
                      <li>Kubernetes, Docker</li>
                      <li>Infrastructure Management</li>
                      <li>CICD implementation</li>
                      <li>Monitoring & logging</li>
                      <li>Database administration</li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 d-grid">
                  <div className="glassCard">
                    <img
                      src="/img/services/web_based_application.svg"
                      alt="web_based_application"
                    />
                    <h4>Full Stack Development</h4>
                    <ul>
                      <li>Backend: Node.JS, PHP, GraphQL, SQL</li>
                      <li>
                        Frontend: React.JS, Typescript, Angular, Redux, HTML,
                        CSS
                      </li>
                      <li>Automation Testing: Selenium, TestNG</li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 d-grid">
                  <div className="glassCard">
                    <img
                      src="/img/services/embedded-system.svg"
                      alt="embedded-system"
                    />
                    <h4>Embedded Systems and IoT</h4>
                    <ul>
                      <li>Firmware Development</li>
                      <li>Linux Device Driver Development</li>
                      <li>
                        IoT Application Development:
                        <br />
                        AWS IoT, Azure IoT
                      </li>
                      <li>
                        Major Areas of Expertise:
                        <ul>
                          <li>Automotive</li>
                          <li>Energy Monitoring and Management</li>
                          <li>Networking</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="wayWorking" className="sectionPad">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="mainTitle">
                    <h2>Our way of Working</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="glassCard">
                    <div className="img-section">
                      <img
                        src="/img/digital-core-capabilities.svg"
                        alt="talent"
                      />
                    </div>
                    <div className="textSection">
                      <h3>Digital Core Capabilities</h3>
                      <p>
                        Build vital capabilities to deliver digital outcomes.
                      </p>
                    </div>
                  </div>
                  <div className="glassCard">
                    <div className="img-section">
                      <img src="/img/digital-operating.svg" alt="talent" />
                    </div>
                    <div className="textSection">
                      <h3>Digital Operating Models</h3>
                      <p>Adopt accelerators to evolve your way of working.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="glassCard">
                    <div className="img-section">
                      <img src="/img/talent.svg" alt="talent" />
                    </div>
                    <div className="textSection">
                      <h3>Empowering Talent Transformations</h3>
                      <p>
                        Embrace the talent revolution to remain relevant in the
                        future
                      </p>
                    </div>
                  </div>
                  <div className="glassCard">
                    <div className="img-section">
                      <img src="/img/next.svg" alt="talent" />
                    </div>
                    <div className="textSection">
                      <h3>The next</h3>
                      <p>
                        We bring you powerful advantages to navigate your
                        digital transformation
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="engagementModel" className="sectionPad">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="mainTitle">
                    <h2>Flexible Engagement Models</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 d-grid">
                  <div className="glassCard">
                    <img
                      src="/img/engagement/team-augmentation.svg"
                      alt="cloud_administration"
                    />
                    <h4>Remote Team Augmentation</h4>
                    <p>
                      Access temporary or permanent remote team members to
                      bolster your workforce.
                    </p>
                    <ul>
                      <li>Hire Quickly</li>
                      <li>Leading the Future of Work</li>
                      <li>The Top 3%</li>
                      <li>A Level Above</li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 d-grid">
                  <div className="glassCard">
                    <img
                      src="/img/engagement/contract-hire.svg"
                      alt="web_based_application"
                    />
                    <h4>Contract to Hire</h4>
                    <p>
                      Secure contract-based talent with the option to bring them
                      onboard permanently.
                    </p>
                    <ul>
                      <li>Try Before You Commit</li>
                      <li>Talent Pipeline</li>
                      <li>Immediate Project Impact</li>
                      <li>Reduced Hiring Risk</li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 d-grid">
                  <div className="glassCard">
                    <img
                      src="/img/engagement/off-shore-development.svg"
                      alt="embedded-system"
                    />
                    <h4>Off Shore Development</h4>
                    <p>Receive comprehensive end-to-end development support.</p>
                    <ul>
                      <li>End to end support</li>
                      <li>High Value</li>
                      <li>Cost Efficient</li>
                      <li>Rapid Market Entry</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
        <section id="partner" className="sectionPad">
          <div className="container">
            <div className="col-md-12">
              <h2>Our Expertise</h2>
              <PartnerSlider />
            </div>
          </div>
        </section>
        <section id="offShore" className="sectionPad">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="mainTitle left dark">
                  <h2>Proven Track Record</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-5">
                <p>
                  Our portfolio of completed projects showcases our commitment
                  to excellence. We've helped numerous clients bring their dream
                  products to life, on time and within budget.
                </p>
                <div className="row">
                  <div className="col-lg-5 col-md-6">
                    <div className="whiteCard">
                      <i className="fas fa-smile"></i>
                      <h3>10</h3>
                      <p>Happy Clients</p>
                    </div>
                    <div className="whiteCard">
                      <i className="fas fa-clock"></i>
                      <h3>3</h3>
                      <p>Years of experience</p>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-6 pt-md-5">
                    <div className="whiteCard">
                      <i className="fas fa-book"></i>
                      <h3>11</h3>
                      <p>Projects Completed </p>
                    </div>
                    <div className="whiteCard">
                      <i className="fas fa-award"></i>
                      <h3>15</h3>
                      <p>Appreciations</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 d-grid">
                <img
                  src="https://images.pexels.com/photos/2261059/pexels-photo-2261059.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                  alt="offshore"
                  className="img-fill"
                />
              </div>
            </div>
          </div>
        </section>
        <section id="bookAppointment" className="sectionPad">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="mainTitle dark">
                  <h2>Book Appointment</h2>
                </div>
                <div className="text-center">
                  <p>
                    Are you looking to reach us? Then what are you waiting for
                    book appointments through
                  </p>
                  <img
                    src="/img/calendar-logo.svg"
                    alt="Calendar.com"
                    className="img-fluid"
                  />
                  <br />
                  <a
                    href="https://www.calendar.com/clockhash-technologies/business-consulting/"
                    target="_blank"
                    className="mainBtn big"
                  >
                    Book Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="testimonial">
          <div className="sectionPad">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="mainTitle">
                    <h2>Testimonials</h2>
                  </div>
                </div>
                <div className="col-md-12">
                  <Testimonials />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
export default Landing_new;